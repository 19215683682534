import { defineStore } from 'pinia';
import { getUserCurrency } from '~/utils/objectHelpers';
import { countries } from '~/data/countries';
import { useListingStore } from './listingStores';
import { useBookingStore } from './bookingStore';
import { rates } from '~/data/rates';
export const useCurrencyStore = defineStore('currency', {
	state: () => ({
		currency: null,
		isSelectDiagOpen: false,
		currencyData: null,
		multipliers: 1,
		country: 'US',
		locale: 'en-US',
		currencies: [],
		currentRates: { ...rates },
	}),
	actions: {
		initCurrency(country, locale) {
			this.currency = getUserCurrency(country) || 'USD';
			this.country = country;
			this.isSelectDiagOpen = false;
			this.locale = locale;
			this.changeCountry(country);
		},

		toggleSelectDialog() {
			this.isSelectDiagOpen = !this.isSelectDiagOpen;
		},
		changeCountry(countryCode: string) {
			const bookingStore = useBookingStore();
			bookingStore.setUserCountry(countryCode);
			const countryInfo = countries.find((x) => x.countryCode === countryCode);
			this.currencyData = countryInfo;
			this.generateCurrencies();

			let currency = countryInfo?.currencyCode || 'USD';
			this.multipliers = this.currentRates[currency] || 1;

			if (this.multipliers === 1) currency = 'USD';
			this.currency = currency;

			this.isSelectDiagOpen = false;
			const store = useListingStore();
			store.changeCountry(currency, this.multipliers);
		},
		updateRates(value) {
			this.currentRates = value;
			this.changeCountry(this.country);
		},
		generateCurrencies() {
			const supportedCurrencies = Object.keys(this.currentRates);
			this.currencies = countries.filter((currency) => supportedCurrencies.includes(currency.currencyCode)).sort((a, b) => a.countryName.localeCompare(b.countryName));
			if (!this.currencies.find((currency) => currency.currencyCode === this.currency)) {
				this.currency = 'USD';
			}
		},
	},
	getters: {
		getCurrency: (state) => state.currency,
		getIsSelectDiagOpen: (state) => state.isSelectDiagOpen,
		getLocale: (state) => state.locale,
		getCountry: (state) => state.country,
	},
	persist: {
		storage: persistedState.cookiesWithOptions({
			sameSite: 'strict',
			expires: new Date(new Date().setDate(new Date().getDate() + 30)),
		}),
	},
});
