export const rates =
{
    AED: 3.67303,
    AFN: 70.875497,
    ALL: 92.686336,
    AMD: 388.681875,
    ANG: 1.805557,
    AOA: 874.5,
    ARS: 931.7802,
    AUD: 1.528701,
    AWG: 1.80125,
    AZN: 1.7,
    BAM: 1.808239,
    BBD: 2,
    BDT: 117.713973,
    BGN: 1.80496,
    BHD: 0.376902,
    BIF: 2885.229286,
    BMD: 1,
    BND: 1.346135,
    BOB: 6.923045,
    BRL: 5.616,
    BSD: 1,
    BTC: 0.000015018198,
    BTN: 83.889124,
    BWP: 13.621424,
    BYN: 3.278554,
    BZD: 2.019406,
    CAD: 1.384725,
    CDF: 2840.249958,
    CHF: 0.885746,
    CLF: 0.034651,
    CLP: 956.14,
    CNH: 7.263345,
    CNY: 7.251,
    COP: 4023.461464,
    CRC: 530.138258,
    CUC: 1,
    CUP: 25.75,
    CVE: 101.945728,
    CZK: 23.465125,
    DJF: 178.401512,
    DKK: 6.887688,
    DOP: 59.300759,
    DZD: 134.730848,
    EGP: 48.421999,
    ERN: 15,
    ETB: 57.91902,
    EUR: 0.923035,
    FJD: 2.261,
    FKP: 0.77788,
    GBP: 0.77788,
    GEL: 2.715,
    GGP: 0.77788,
    GHS: 15.528774,
    GIP: 0.77788,
    GMD: 67.775,
    GNF: 8634.412764,
    GTQ: 7.764387,
    GYD: 209.595857,
    HKD: 7.811515,
    HNL: 24.799999,
    HRK: 6.954399,
    HTG: 132.237437,
    HUF: 364.07225,
    IDR: 16331.919451,
    ILS: 3.734879,
    IMP: 0.77788,
    INR: 83.711954,
    IQD: 1312.403561,
    IRR: 42105,
    ISK: 138,
    JEP: 0.77788,
    JMD: 156.757752,
    JOD: 0.7086,
    JPY: 154.735,
    KES: 130,
    KGS: 84.03,
    KHR: 4108.679592,
    KMF: 455.249733,
    KPW: 900,
    KRW: 1384.45558,
    KWD: 0.30577,
    KYD: 0.83487,
    KZT: 475.46895,
    LAK: 22219.73242,
    LBP: 89720.263869,
    LKR: 303.262335,
    LRD: 195.75538,
    LSL: 18.401819,
    LYD: 4.835,
    MAD: 9.881981,
    MDL: 17.724305,
    MGA: 4555,
    MKD: 56.966267,
    MMK: 2098,
    MNT: 3398,
    MOP: 8.060336,
    MRU: 39.651817,
    MUR: 46.649997,
    MVR: 15.365,
    MWK: 1737.226458,
    MXN: 18.618552,
    MYR: 4.6225,
    MZN: 63.850001,
    NAD: 18.401819,
    NGN: 1620,
    NIO: 36.880034,
    NOK: 10.960486,
    NPR: 134.222738,
    NZD: 1.697192,
    OMR: 0.384961,
    PAB: 1,
    PEN: 3.742679,
    PGK: 3.932083,
    PHP: 58.701502,
    PKR: 278.5375,
    PLN: 3.953314,
    PYG: 7586.865654,
    QAR: 3.656418,
    RON: 4.5922,
    RSD: 108.069538,
    RUB: 86.427773,
    RWF: 1317.5,
    SAR: 3.751529,
    SBD: 8.41789,
    SCR: 13.158806,
    SDG: 599.5,
    SEK: 10.787154,
    SGD: 1.343984,
    SHP: 0.77788,
    SLL: 20969.5,
    SOS: 572.57456,
    SRD: 28.8535,
    SSP: 130.26,
    STD: 22281.8,
    STN: 22.651313,
    SVC: 8.765677,
    SYP: 2512.53,
    SZL: 18.39581,
    THB: 35.99,
    TJS: 10.634474,
    TMT: 3.355,
    TND: 3.118976,
    TOP: 2.3857,
    TRY: 33.087923,
    TTD: 6.803778,
    TWD: 32.853072,
    TZS: 2704.962,
    UAH: 41.102066,
    UGX: 3731.510449,
    USD: 1,
    UYU: 40.280141,
    UZS: 12595,
    VES: 36.563528,
    VND: 25287.650623,
    VUV: 118.722,
    WST: 2.8,
    XAF: 605.471479,
    XAG: 0.0358213,
    XAU: 0.00041814,
    XCD: 2.70255,
    XDR: 0.755266,
    XOF: 605.471479,
    XPD: 0.00113172,
    XPF: 110.147413,
    XPT: 0.00105155,
    YER: 250.345029,
    ZAR: 18.314244,
    ZMW: 26.223066,
    ZWL: 322,
};