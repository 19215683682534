import { default as _91slug_93RyVVoKkmcHMeta } from "/vercel/path0/pages/[slug].vue?macro=true";
import { default as _404lZJpwxvtooMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as _91slug_93_46clientHGCXT5rsnmMeta } from "/vercel/path0/pages/booking/[slug].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as cancell4K40oCkPKMeta } from "/vercel/path0/pages/cancel.vue?macro=true";
import { default as _91ref_932b0rdexGzoMeta } from "/vercel/path0/pages/cart/[ref].vue?macro=true";
import { default as indextHkCpcsk7qMeta } from "/vercel/path0/pages/cart/index.vue?macro=true";
import { default as confirmationBwVU05JSLdMeta } from "/vercel/path0/pages/confirmation.vue?macro=true";
import { default as _91destination_93U1H7a7B2GLMeta } from "/vercel/path0/pages/destination/[destination].vue?macro=true";
import { default as destinationsjn09LJjFXhMeta } from "/vercel/path0/pages/destinations.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91ref_93gGhXPd6WKpMeta } from "/vercel/path0/pages/participants/[ref].vue?macro=true";
import { default as paymentqSZWcJbf8VMeta } from "/vercel/path0/pages/payment.vue?macro=true";
import { default as _91slug_93KsbhNI1yQEMeta } from "/vercel/path0/pages/tours/[slug].vue?macro=true";
import { default as indexemJKwxSY7PMeta } from "/vercel/path0/pages/tours/index.vue?macro=true";
import { default as _91slug_93A05yCGdaHqMeta } from "/vercel/path0/pages/transfers/[slug].vue?macro=true";
import { default as _91slug_93eGNm1kuXTFMeta } from "/vercel/path0/pages/transportation/[slug].vue?macro=true";
import { default as index0yFqhYR1szMeta } from "/vercel/path0/pages/transportation/index.vue?macro=true";
import { default as component_45stubUHgG5aOkzGMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubUHgG5aOkzG } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91slug_93RyVVoKkmcHMeta?.name ?? "slug",
    path: _91slug_93RyVVoKkmcHMeta?.path ?? "/:slug()",
    meta: _91slug_93RyVVoKkmcHMeta || {},
    alias: _91slug_93RyVVoKkmcHMeta?.alias || [],
    redirect: _91slug_93RyVVoKkmcHMeta?.redirect,
    component: () => import("/vercel/path0/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _404lZJpwxvtooMeta?.name ?? "404",
    path: _404lZJpwxvtooMeta?.path ?? "/404",
    meta: _404lZJpwxvtooMeta || {},
    alias: _404lZJpwxvtooMeta?.alias || [],
    redirect: _404lZJpwxvtooMeta?.redirect,
    component: () => import("/vercel/path0/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93_46clientHGCXT5rsnmMeta?.name ?? "booking-slug",
    path: _91slug_93_46clientHGCXT5rsnmMeta?.path ?? "/booking/:slug()",
    meta: _91slug_93_46clientHGCXT5rsnmMeta || {},
    alias: _91slug_93_46clientHGCXT5rsnmMeta?.alias || [],
    redirect: _91slug_93_46clientHGCXT5rsnmMeta?.redirect,
    component: () => createClientPage(() => import("/vercel/path0/pages/booking/[slug].client.vue").then(m => m.default || m))
  },
  {
    name: cancell4K40oCkPKMeta?.name ?? "cancel",
    path: cancell4K40oCkPKMeta?.path ?? "/cancel",
    meta: cancell4K40oCkPKMeta || {},
    alias: cancell4K40oCkPKMeta?.alias || [],
    redirect: cancell4K40oCkPKMeta?.redirect,
    component: () => import("/vercel/path0/pages/cancel.vue").then(m => m.default || m)
  },
  {
    name: _91ref_932b0rdexGzoMeta?.name ?? "cart-ref",
    path: _91ref_932b0rdexGzoMeta?.path ?? "/cart/:ref()",
    meta: _91ref_932b0rdexGzoMeta || {},
    alias: _91ref_932b0rdexGzoMeta?.alias || [],
    redirect: _91ref_932b0rdexGzoMeta?.redirect,
    component: () => import("/vercel/path0/pages/cart/[ref].vue").then(m => m.default || m)
  },
  {
    name: indextHkCpcsk7qMeta?.name ?? "cart",
    path: indextHkCpcsk7qMeta?.path ?? "/cart",
    meta: indextHkCpcsk7qMeta || {},
    alias: indextHkCpcsk7qMeta?.alias || [],
    redirect: indextHkCpcsk7qMeta?.redirect,
    component: () => import("/vercel/path0/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: confirmationBwVU05JSLdMeta?.name ?? "confirmation",
    path: confirmationBwVU05JSLdMeta?.path ?? "/confirmation",
    meta: confirmationBwVU05JSLdMeta || {},
    alias: confirmationBwVU05JSLdMeta?.alias || [],
    redirect: confirmationBwVU05JSLdMeta?.redirect,
    component: () => import("/vercel/path0/pages/confirmation.vue").then(m => m.default || m)
  },
  {
    name: _91destination_93U1H7a7B2GLMeta?.name ?? "destination-destination",
    path: _91destination_93U1H7a7B2GLMeta?.path ?? "/destination/:destination()",
    meta: _91destination_93U1H7a7B2GLMeta || {},
    alias: _91destination_93U1H7a7B2GLMeta?.alias || [],
    redirect: _91destination_93U1H7a7B2GLMeta?.redirect,
    component: () => import("/vercel/path0/pages/destination/[destination].vue").then(m => m.default || m)
  },
  {
    name: destinationsjn09LJjFXhMeta?.name ?? "destinations",
    path: destinationsjn09LJjFXhMeta?.path ?? "/destinations",
    meta: destinationsjn09LJjFXhMeta || {},
    alias: destinationsjn09LJjFXhMeta?.alias || [],
    redirect: destinationsjn09LJjFXhMeta?.redirect,
    component: () => import("/vercel/path0/pages/destinations.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91ref_93gGhXPd6WKpMeta?.name ?? "participants-ref",
    path: _91ref_93gGhXPd6WKpMeta?.path ?? "/participants/:ref()",
    meta: _91ref_93gGhXPd6WKpMeta || {},
    alias: _91ref_93gGhXPd6WKpMeta?.alias || [],
    redirect: _91ref_93gGhXPd6WKpMeta?.redirect,
    component: () => import("/vercel/path0/pages/participants/[ref].vue").then(m => m.default || m)
  },
  {
    name: paymentqSZWcJbf8VMeta?.name ?? "payment",
    path: paymentqSZWcJbf8VMeta?.path ?? "/payment",
    meta: paymentqSZWcJbf8VMeta || {},
    alias: paymentqSZWcJbf8VMeta?.alias || [],
    redirect: paymentqSZWcJbf8VMeta?.redirect,
    component: () => import("/vercel/path0/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93KsbhNI1yQEMeta?.name ?? "tours-slug",
    path: _91slug_93KsbhNI1yQEMeta?.path ?? "/tours/:slug()",
    meta: _91slug_93KsbhNI1yQEMeta || {},
    alias: _91slug_93KsbhNI1yQEMeta?.alias || [],
    redirect: _91slug_93KsbhNI1yQEMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexemJKwxSY7PMeta?.name ?? "tours",
    path: indexemJKwxSY7PMeta?.path ?? "/tours",
    meta: indexemJKwxSY7PMeta || {},
    alias: indexemJKwxSY7PMeta?.alias || [],
    redirect: indexemJKwxSY7PMeta?.redirect,
    component: () => import("/vercel/path0/pages/tours/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93A05yCGdaHqMeta?.name ?? "transfers-slug",
    path: _91slug_93A05yCGdaHqMeta?.path ?? "/transfers/:slug()",
    meta: _91slug_93A05yCGdaHqMeta || {},
    alias: _91slug_93A05yCGdaHqMeta?.alias || [],
    redirect: _91slug_93A05yCGdaHqMeta?.redirect,
    component: () => import("/vercel/path0/pages/transfers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eGNm1kuXTFMeta?.name ?? "transportation-slug",
    path: _91slug_93eGNm1kuXTFMeta?.path ?? "/transportation/:slug()",
    meta: _91slug_93eGNm1kuXTFMeta || {},
    alias: _91slug_93eGNm1kuXTFMeta?.alias || [],
    redirect: _91slug_93eGNm1kuXTFMeta?.redirect,
    component: () => import("/vercel/path0/pages/transportation/[slug].vue").then(m => m.default || m)
  },
  {
    name: index0yFqhYR1szMeta?.name ?? "transportation",
    path: index0yFqhYR1szMeta?.path ?? "/transportation",
    meta: index0yFqhYR1szMeta || {},
    alias: index0yFqhYR1szMeta?.alias || [],
    redirect: index0yFqhYR1szMeta?.redirect,
    component: () => import("/vercel/path0/pages/transportation/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/privatetour-details-PPS-PV-PPUREN",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/airport-transfers",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  }
]