export const countries = [
    {
        "name": "Aruban florin",
        "symbol": "ƒ",
        "countryName": "Aruba",
        "countryCode": "AW",
        "currencyCode": "AWG",
        "flag": "🇦🇼"
    },
    {
        "name": "Afghan afghani",
        "symbol": "؋",
        "countryName": "Afghanistan",
        "countryCode": "AF",
        "currencyCode": "AFN",
        "flag": "🇦🇫"
    },
    {
        "name": "Angolan kwanza",
        "symbol": "Kz",
        "countryName": "Angola",
        "countryCode": "AO",
        "currencyCode": "AOA",
        "flag": "🇦🇴"
    },
    {
        "name": "Eastern Caribbean dollar",
        "symbol": "$",
        "countryName": "Anguilla",
        "countryCode": "AI",
        "currencyCode": "XCD",
        "flag": "🇦🇮"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Åland Islands",
        "countryCode": "AX",
        "currencyCode": "EUR",
        "flag": "🇦🇽"
    },
    {
        "name": "Albanian lek",
        "symbol": "L",
        "countryName": "Albania",
        "countryCode": "AL",
        "currencyCode": "ALL",
        "flag": "🇦🇱"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "AndorrA",
        "countryCode": "AD",
        "currencyCode": "EUR",
        "flag": "🇦🇩"
    },
    {
        "name": "United Arab Emirates dirham",
        "symbol": "د.إ",
        "countryName": "United Arab Emirates",
        "countryCode": "AE",
        "currencyCode": "AED",
        "flag": "🇦🇪"
    },
    {
        "name": "Argentine peso",
        "symbol": "$",
        "countryName": "Argentina",
        "countryCode": "AR",
        "currencyCode": "ARS",
        "flag": "🇦🇷"
    },
    {
        "name": "Armenian dram",
        "symbol": "֏",
        "countryName": "Armenia",
        "countryCode": "AM",
        "currencyCode": "AMD",
        "flag": "🇦🇲"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "American Samoa",
        "countryCode": "AS",
        "currencyCode": "USD",
        "flag": "🇦🇸"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "French Southern Territories",
        "countryCode": "TF",
        "currencyCode": "EUR",
        "flag": "🇹🇫"
    },
    {
        "name": "Eastern Caribbean dollar",
        "symbol": "$",
        "countryName": "Antigua and Barbuda",
        "countryCode": "AG",
        "currencyCode": "XCD",
        "flag": "🇦🇬"
    },
    {
        "name": "Australian dollar",
        "symbol": "$",
        "countryName": "Australia",
        "countryCode": "AU",
        "currencyCode": "AUD",
        "flag": "🇦🇺"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Austria",
        "countryCode": "AT",
        "currencyCode": "EUR",
        "flag": "🇦🇹"
    },
    {
        "name": "Azerbaijani manat",
        "symbol": "₼",
        "countryName": "Azerbaijan",
        "countryCode": "AZ",
        "currencyCode": "AZN",
        "flag": "🇦🇿"
    },
    {
        "name": "Burundian franc",
        "symbol": "Fr",
        "countryName": "Burundi",
        "countryCode": "BI",
        "currencyCode": "BIF",
        "flag": "🇧🇮"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Belgium",
        "countryCode": "BE",
        "currencyCode": "EUR",
        "flag": "🇧🇪"
    },
    {
        "name": "West African CFA franc",
        "symbol": "Fr",
        "countryName": "Benin",
        "countryCode": "BJ",
        "currencyCode": "XOF",
        "flag": "🇧🇯"
    },
    {
        "name": "West African CFA franc",
        "symbol": "Fr",
        "countryName": "Burkina Faso",
        "countryCode": "BF",
        "currencyCode": "XOF",
        "flag": "🇧🇫"
    },
    {
        "name": "Bangladeshi taka",
        "symbol": "৳",
        "countryName": "Bangladesh",
        "countryCode": "BD",
        "currencyCode": "BDT",
        "flag": "🇧🇩"
    },
    {
        "name": "Bulgarian lev",
        "symbol": "лв",
        "countryName": "Bulgaria",
        "countryCode": "BG",
        "currencyCode": "BGN",
        "flag": "🇧🇬"
    },
    {
        "name": "Bahraini dinar",
        "symbol": ".د.ب",
        "countryName": "Bahrain",
        "countryCode": "BH",
        "currencyCode": "BHD",
        "flag": "🇧🇭"
    },
    {
        "name": "Bahamian dollar",
        "symbol": "$",
        "countryName": "Bahamas",
        "countryCode": "BS",
        "currencyCode": "BSD",
        "flag": "🇧🇸"
    },
    {
        "name": "Bosnia and Herzegovina convertible mark",
        "symbol": "",
        "countryName": "Bosnia and Herzegovina",
        "countryCode": "BA",
        "currencyCode": "BAM",
        "flag": "🇧🇦"
    },
    {
        "name": "Pound sterling",
        "symbol": "£",
        "countryName": "Saint Helena",
        "countryCode": "SH",
        "currencyCode": "GBP",
        "flag": "🇸🇭"
    },
    {
        "name": "Belarusian ruble",
        "symbol": "Br",
        "countryName": "Belarus",
        "countryCode": "BY",
        "currencyCode": "BYN",
        "flag": "🇧🇾"
    },
    {
        "name": "Belize dollar",
        "symbol": "$",
        "countryName": "Belize",
        "countryCode": "BZ",
        "currencyCode": "BZD",
        "flag": "🇧🇿"
    },
    {
        "name": "Bermudian dollar",
        "symbol": "$",
        "countryName": "Bermuda",
        "countryCode": "BM",
        "currencyCode": "BMD",
        "flag": "🇧🇲"
    },
    {
        "name": "Bolivian boliviano",
        "symbol": "Bs.",
        "countryName": "Bolivia",
        "countryCode": "BO",
        "currencyCode": "BOB",
        "flag": "🇧🇴"
    },
    {
        "name": "Brazilian real",
        "symbol": "R$",
        "countryName": "Brazil",
        "countryCode": "BR",
        "currencyCode": "BRL",
        "flag": "🇧🇷"
    },
    {
        "name": "Barbadian dollar",
        "symbol": "$",
        "countryName": "Barbados",
        "countryCode": "BB",
        "currencyCode": "BBD",
        "flag": "🇧🇧"
    },
    {
        "name": "Brunei dollar",
        "symbol": "$",
        "countryName": "Brunei Darussalam",
        "countryCode": "BN",
        "currencyCode": "BND",
        "flag": "🇧🇳"
    },
    {
        "name": "Bhutanese ngultrum",
        "symbol": "Nu.",
        "countryName": "Bhutan",
        "countryCode": "BT",
        "currencyCode": "BTN",
        "flag": "🇧🇹"
    },
    {
        "name": "Botswana pula",
        "symbol": "P",
        "countryName": "Botswana",
        "countryCode": "BW",
        "currencyCode": "BWP",
        "flag": "🇧🇼"
    },
    {
        "name": "Central African CFA franc",
        "symbol": "Fr",
        "countryName": "Central African Republic",
        "countryCode": "CF",
        "currencyCode": "XAF",
        "flag": "🇨🇫"
    },
    {
        "name": "Canadian dollar",
        "symbol": "$",
        "countryName": "Canada",
        "countryCode": "CA",
        "currencyCode": "CAD",
        "flag": "🇨🇦"
    },
    {
        "name": "Australian dollar",
        "symbol": "$",
        "countryName": "Cocos (Keeling) Islands",
        "countryCode": "CC",
        "currencyCode": "AUD",
        "flag": "🇨🇨"
    },
    {
        "name": "Swiss franc",
        "symbol": "Fr.",
        "countryName": "Switzerland",
        "countryCode": "CH",
        "currencyCode": "CHF",
        "flag": "🇨🇭"
    },
    {
        "name": "Chilean peso",
        "symbol": "$",
        "countryName": "Chile",
        "countryCode": "CL",
        "currencyCode": "CLP",
        "flag": "🇨🇱"
    },
    {
        "name": "Chinese yuan",
        "symbol": "¥",
        "countryName": "China",
        "countryCode": "CN",
        "currencyCode": "CNY",
        "flag": "🇨🇳"
    },
    {
        "name": "West African CFA franc",
        "symbol": "Fr",
        "countryName": "Cote D'Ivoire",
        "countryCode": "CI",
        "currencyCode": "XOF",
        "flag": "🇨🇮"
    },
    {
        "name": "Central African CFA franc",
        "symbol": "Fr",
        "countryName": "Cameroon",
        "countryCode": "CM",
        "currencyCode": "XAF",
        "flag": "🇨🇲"
    },
    {
        "name": "Congolese franc",
        "symbol": "FC",
        "countryName": "Congo, The Democratic Republic of the",
        "countryCode": "CD",
        "currencyCode": "CDF",
        "flag": "🇨🇩"
    },
    {
        "name": "Central African CFA franc",
        "symbol": "Fr",
        "countryName": "Congo",
        "countryCode": "CG",
        "currencyCode": "XAF",
        "flag": "🇨🇬"
    },
    {
        "name": "Cook Islands dollar",
        "symbol": "$",
        "countryName": "Cook Islands",
        "countryCode": "CK",
        "currencyCode": "CKD",
        "flag": "🇨🇰"
    },
    {
        "name": "Colombian peso",
        "symbol": "$",
        "countryName": "Colombia",
        "countryCode": "CO",
        "currencyCode": "COP",
        "flag": "🇨🇴"
    },
    {
        "name": "Comorian franc",
        "symbol": "Fr",
        "countryName": "Comoros",
        "countryCode": "KM",
        "currencyCode": "KMF",
        "flag": "🇰🇲"
    },
    {
        "name": "Cape Verdean escudo",
        "symbol": "Esc",
        "countryName": "Cape Verde",
        "countryCode": "CV",
        "currencyCode": "CVE",
        "flag": "🇨🇻"
    },
    {
        "name": "Costa Rican colón",
        "symbol": "₡",
        "countryName": "Costa Rica",
        "countryCode": "CR",
        "currencyCode": "CRC",
        "flag": "🇨🇷"
    },
    {
        "name": "Cuban convertible peso",
        "symbol": "$",
        "countryName": "Cuba",
        "countryCode": "CU",
        "currencyCode": "CUC",
        "flag": "🇨🇺"
    },
    {
        "name": "Australian dollar",
        "symbol": "$",
        "countryName": "Christmas Island",
        "countryCode": "CX",
        "currencyCode": "AUD",
        "flag": "🇨🇽"
    },
    {
        "name": "Cayman Islands dollar",
        "symbol": "$",
        "countryName": "Cayman Islands",
        "countryCode": "KY",
        "currencyCode": "KYD",
        "flag": "🇰🇾"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Cyprus",
        "countryCode": "CY",
        "currencyCode": "EUR",
        "flag": "🇨🇾"
    },
    {
        "name": "Czech koruna",
        "symbol": "Kč",
        "countryName": "Czech Republic",
        "countryCode": "CZ",
        "currencyCode": "CZK",
        "flag": "🇨🇿"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Germany",
        "countryCode": "DE",
        "currencyCode": "EUR",
        "flag": "🇩🇪"
    },
    {
        "name": "Djiboutian franc",
        "symbol": "Fr",
        "countryName": "Djibouti",
        "countryCode": "DJ",
        "currencyCode": "DJF",
        "flag": "🇩🇯"
    },
    {
        "name": "Eastern Caribbean dollar",
        "symbol": "$",
        "countryName": "Dominica",
        "countryCode": "DM",
        "currencyCode": "XCD",
        "flag": "🇩🇲"
    },
    {
        "name": "Danish krone",
        "symbol": "kr",
        "countryName": "Denmark",
        "countryCode": "DK",
        "currencyCode": "DKK",
        "flag": "🇩🇰"
    },
    {
        "name": "Dominican peso",
        "symbol": "$",
        "countryName": "Dominican Republic",
        "countryCode": "DO",
        "currencyCode": "DOP",
        "flag": "🇩🇴"
    },
    {
        "name": "Algerian dinar",
        "symbol": "د.ج",
        "countryName": "Algeria",
        "countryCode": "DZ",
        "currencyCode": "DZD",
        "flag": "🇩🇿"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Ecuador",
        "countryCode": "EC",
        "currencyCode": "USD",
        "flag": "🇪🇨"
    },
    {
        "name": "Egyptian pound",
        "symbol": "£",
        "countryName": "Egypt",
        "countryCode": "EG",
        "currencyCode": "EGP",
        "flag": "🇪🇬"
    },
    {
        "name": "Eritrean nakfa",
        "symbol": "Nfk",
        "countryName": "Eritrea",
        "countryCode": "ER",
        "currencyCode": "ERN",
        "flag": "🇪🇷"
    },
    {
        "name": "Algerian dinar",
        "symbol": "دج",
        "countryName": "Western Sahara",
        "countryCode": "EH",
        "currencyCode": "DZD",
        "flag": "🇪🇭"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Spain",
        "countryCode": "ES",
        "currencyCode": "EUR",
        "flag": "🇪🇸"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Estonia",
        "countryCode": "EE",
        "currencyCode": "EUR",
        "flag": "🇪🇪"
    },
    {
        "name": "Ethiopian birr",
        "symbol": "Br",
        "countryName": "Ethiopia",
        "countryCode": "ET",
        "currencyCode": "ETB",
        "flag": "🇪🇹"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Finland",
        "countryCode": "FI",
        "currencyCode": "EUR",
        "flag": "🇫🇮"
    },
    {
        "name": "Fijian dollar",
        "symbol": "$",
        "countryName": "Fiji",
        "countryCode": "FJ",
        "currencyCode": "FJD",
        "flag": "🇫🇯"
    },
    {
        "name": "Falkland Islands pound",
        "symbol": "£",
        "countryName": "Falkland Islands (Malvinas)",
        "countryCode": "FK",
        "currencyCode": "FKP",
        "flag": "🇫🇰"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "France",
        "countryCode": "FR",
        "currencyCode": "EUR",
        "flag": "🇫🇷"
    },
    {
        "name": "Danish krone",
        "symbol": "kr",
        "countryName": "Faroe Islands",
        "countryCode": "FO",
        "currencyCode": "DKK",
        "flag": "🇫🇴"
    },
    {
        "name": "Central African CFA franc",
        "symbol": "Fr",
        "countryName": "Gabon",
        "countryCode": "GA",
        "currencyCode": "XAF",
        "flag": "🇬🇦"
    },
    {
        "name": "British pound",
        "symbol": "£",
        "countryName": "United Kingdom",
        "countryCode": "GB",
        "currencyCode": "GBP",
        "flag": "🇬🇧"
    },
    {
        "name": "lari",
        "symbol": "₾",
        "countryName": "Georgia",
        "countryCode": "GE",
        "currencyCode": "GEL",
        "flag": "🇬🇪"
    },
    {
        "name": "British pound",
        "symbol": "£",
        "countryName": "Guernsey",
        "countryCode": "GG",
        "currencyCode": "GBP",
        "flag": "🇬🇬"
    },
    {
        "name": "Ghanaian cedi",
        "symbol": "₵",
        "countryName": "Ghana",
        "countryCode": "GH",
        "currencyCode": "GHS",
        "flag": "🇬🇭"
    },
    {
        "name": "Gibraltar pound",
        "symbol": "£",
        "countryName": "Gibraltar",
        "countryCode": "GI",
        "currencyCode": "GIP",
        "flag": "🇬🇮"
    },
    {
        "name": "Guinean franc",
        "symbol": "Fr",
        "countryName": "Guinea",
        "countryCode": "GN",
        "currencyCode": "GNF",
        "flag": "🇬🇳"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Guadeloupe",
        "countryCode": "GP",
        "currencyCode": "EUR",
        "flag": "🇬🇵"
    },
    {
        "name": "dalasi",
        "symbol": "D",
        "countryName": "Gambia",
        "countryCode": "GM",
        "currencyCode": "GMD",
        "flag": "🇬🇲"
    },
    {
        "name": "West African CFA franc",
        "symbol": "Fr",
        "countryName": "Guinea-Bissau",
        "countryCode": "GW",
        "currencyCode": "XOF",
        "flag": "🇬🇼"
    },
    {
        "name": "Central African CFA franc",
        "symbol": "Fr",
        "countryName": "Equatorial Guinea",
        "countryCode": "GQ",
        "currencyCode": "XAF",
        "flag": "🇬🇶"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Greece",
        "countryCode": "GR",
        "currencyCode": "EUR",
        "flag": "🇬🇷"
    },
    {
        "name": "Eastern Caribbean dollar",
        "symbol": "$",
        "countryName": "Grenada",
        "countryCode": "GD",
        "currencyCode": "XCD",
        "flag": "🇬🇩"
    },
    {
        "name": "krone",
        "symbol": "kr.",
        "countryName": "Greenland",
        "countryCode": "GL",
        "currencyCode": "DKK",
        "flag": "🇬🇱"
    },
    {
        "name": "Guatemalan quetzal",
        "symbol": "Q",
        "countryName": "Guatemala",
        "countryCode": "GT",
        "currencyCode": "GTQ",
        "flag": "🇬🇹"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "French Guiana",
        "countryCode": "GF",
        "currencyCode": "EUR",
        "flag": "🇬🇫"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Guam",
        "countryCode": "GU",
        "currencyCode": "USD",
        "flag": "🇬🇺"
    },
    {
        "name": "Guyanese dollar",
        "symbol": "$",
        "countryName": "Guyana",
        "countryCode": "GY",
        "currencyCode": "GYD",
        "flag": "🇬🇾"
    },
    {
        "name": "Hong Kong dollar",
        "symbol": "$",
        "countryName": "Hong Kong",
        "countryCode": "HK",
        "currencyCode": "HKD",
        "flag": "🇭🇰"
    },
    {
        "name": "Honduran lempira",
        "symbol": "L",
        "countryName": "Honduras",
        "countryCode": "HN",
        "currencyCode": "HNL",
        "flag": "🇭🇳"
    },
    {
        "name": "Croatian kuna",
        "symbol": "kn",
        "countryName": "Croatia",
        "countryCode": "HR",
        "currencyCode": "HRK",
        "flag": "🇭🇷"
    },
    {
        "name": "Haitian gourde",
        "symbol": "G",
        "countryName": "Haiti",
        "countryCode": "HT",
        "currencyCode": "HTG",
        "flag": "🇭🇹"
    },
    {
        "name": "Hungarian forint",
        "symbol": "Ft",
        "countryName": "Hungary",
        "countryCode": "HU",
        "currencyCode": "HUF",
        "flag": "🇭🇺"
    },
    {
        "name": "Indonesian rupiah",
        "symbol": "Rp",
        "countryName": "Indonesia",
        "countryCode": "ID",
        "currencyCode": "IDR",
        "flag": "🇮🇩"
    },
    {
        "name": "British pound",
        "symbol": "£",
        "countryName": "Isle of Man",
        "countryCode": "IM",
        "currencyCode": "GBP",
        "flag": "🇮🇲"
    },
    {
        "name": "Indian rupee",
        "symbol": "₹",
        "countryName": "India",
        "countryCode": "IN",
        "currencyCode": "INR",
        "flag": "🇮🇳"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "British Indian Ocean Territory",
        "countryCode": "IO",
        "currencyCode": "USD",
        "flag": "🇮🇴"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Ireland",
        "countryCode": "IE",
        "currencyCode": "EUR",
        "flag": "🇮🇪"
    },
    {
        "name": "Iranian rial",
        "symbol": "﷼",
        "countryName": "Iran, Islamic Republic Of",
        "countryCode": "IR",
        "currencyCode": "IRR",
        "flag": "🇮🇷"
    },
    {
        "name": "Iraqi dinar",
        "symbol": "ع.د",
        "countryName": "Iraq",
        "countryCode": "IQ",
        "currencyCode": "IQD",
        "flag": "🇮🇶"
    },
    {
        "name": "Icelandic króna",
        "symbol": "kr",
        "countryName": "Iceland",
        "countryCode": "IS",
        "currencyCode": "ISK",
        "flag": "🇮🇸"
    },
    {
        "name": "Israeli new shekel",
        "symbol": "₪",
        "countryName": "Israel",
        "countryCode": "IL",
        "currencyCode": "ILS",
        "flag": "🇮🇱"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Italy",
        "countryCode": "IT",
        "currencyCode": "EUR",
        "flag": "🇮🇹"
    },
    {
        "name": "Jamaican dollar",
        "symbol": "$",
        "countryName": "Jamaica",
        "countryCode": "JM",
        "currencyCode": "JMD",
        "flag": "🇯🇲"
    },
    {
        "name": "British pound",
        "symbol": "£",
        "countryName": "Jersey",
        "countryCode": "JE",
        "currencyCode": "GBP",
        "flag": "🇯🇪"
    },
    {
        "name": "Jordanian dinar",
        "symbol": "د.ا",
        "countryName": "Jordan",
        "countryCode": "JO",
        "currencyCode": "JOD",
        "flag": "🇯🇴"
    },
    {
        "name": "Japanese yen",
        "symbol": "¥",
        "countryName": "Japan",
        "countryCode": "JP",
        "currencyCode": "JPY",
        "flag": "🇯🇵"
    },
    {
        "name": "Kazakhstani tenge",
        "symbol": "₸",
        "countryName": "Kazakhstan",
        "countryCode": "KZ",
        "currencyCode": "KZT",
        "flag": "🇰🇿"
    },
    {
        "name": "Kenyan shilling",
        "symbol": "Sh",
        "countryName": "Kenya",
        "countryCode": "KE",
        "currencyCode": "KES",
        "flag": "🇰🇪"
    },
    {
        "name": "Kyrgyzstani som",
        "symbol": "с",
        "countryName": "Kyrgyzstan",
        "countryCode": "KG",
        "currencyCode": "KGS",
        "flag": "🇰🇬"
    },
    {
        "name": "Cambodian riel",
        "symbol": "៛",
        "countryName": "Cambodia",
        "countryCode": "KH",
        "currencyCode": "KHR",
        "flag": "🇰🇭"
    },
    {
        "name": "Australian dollar",
        "symbol": "$",
        "countryName": "Kiribati",
        "countryCode": "KI",
        "currencyCode": "AUD",
        "flag": "🇰🇮"
    },
    {
        "name": "Eastern Caribbean dollar",
        "symbol": "$",
        "countryName": "Saint Kitts and Nevis",
        "countryCode": "KN",
        "currencyCode": "XCD",
        "flag": "🇰🇳"
    },
    {
        "name": "South Korean won",
        "symbol": "₩",
        "countryName": "Korea, Republic of",
        "countryCode": "KR",
        "currencyCode": "KRW",
        "flag": "🇰🇷"
    },
    {
        "name": "Kuwaiti dinar",
        "symbol": "د.ك",
        "countryName": "Kuwait",
        "countryCode": "KW",
        "currencyCode": "KWD",
        "flag": "🇰🇼"
    },
    {
        "name": "Lao kip",
        "symbol": "₭",
        "countryName": "Lao People'S Democratic Republic",
        "countryCode": "LA",
        "currencyCode": "LAK",
        "flag": "🇱🇦"
    },
    {
        "name": "Lebanese pound",
        "symbol": "ل.ل",
        "countryName": "Lebanon",
        "countryCode": "LB",
        "currencyCode": "LBP",
        "flag": "🇱🇧"
    },
    {
        "name": "Liberian dollar",
        "symbol": "$",
        "countryName": "Liberia",
        "countryCode": "LR",
        "currencyCode": "LRD",
        "flag": "🇱🇷"
    },
    {
        "name": "Libyan dinar",
        "symbol": "ل.د",
        "countryName": "Libyan Arab Jamahiriya",
        "countryCode": "LY",
        "currencyCode": "LYD",
        "flag": "🇱🇾"
    },
    {
        "name": "Eastern Caribbean dollar",
        "symbol": "$",
        "countryName": "Saint Lucia",
        "countryCode": "LC",
        "currencyCode": "XCD",
        "flag": "🇱🇨"
    },
    {
        "name": "Swiss franc",
        "symbol": "Fr",
        "countryName": "Liechtenstein",
        "countryCode": "LI",
        "currencyCode": "CHF",
        "flag": "🇱🇮"
    },
    {
        "name": "Sri Lankan rupee",
        "symbol": "Rs  රු",
        "countryName": "Sri Lanka",
        "countryCode": "LK",
        "currencyCode": "LKR",
        "flag": "🇱🇰"
    },
    {
        "name": "Lesotho loti",
        "symbol": "L",
        "countryName": "Lesotho",
        "countryCode": "LS",
        "currencyCode": "LSL",
        "flag": "🇱🇸"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Lithuania",
        "countryCode": "LT",
        "currencyCode": "EUR",
        "flag": "🇱🇹"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Luxembourg",
        "countryCode": "LU",
        "currencyCode": "EUR",
        "flag": "🇱🇺"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Latvia",
        "countryCode": "LV",
        "currencyCode": "EUR",
        "flag": "🇱🇻"
    },
    {
        "name": "Macanese pataca",
        "symbol": "P",
        "countryName": "Macao",
        "countryCode": "MO",
        "currencyCode": "MOP",
        "flag": "🇲🇴"
    },
    {
        "name": "Moroccan dirham",
        "symbol": "د.م.",
        "countryName": "Morocco",
        "countryCode": "MA",
        "currencyCode": "MAD",
        "flag": "🇲🇦"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Monaco",
        "countryCode": "MC",
        "currencyCode": "EUR",
        "flag": "🇲🇨"
    },
    {
        "name": "Moldovan leu",
        "symbol": "L",
        "countryName": "Moldova, Republic of",
        "countryCode": "MD",
        "currencyCode": "MDL",
        "flag": "🇲🇩"
    },
    {
        "name": "Malagasy ariary",
        "symbol": "Ar",
        "countryName": "Madagascar",
        "countryCode": "MG",
        "currencyCode": "MGA",
        "flag": "🇲🇬"
    },
    {
        "name": "Maldivian rufiyaa",
        "symbol": ".ރ",
        "countryName": "Maldives",
        "countryCode": "MV",
        "currencyCode": "MVR",
        "flag": "🇲🇻"
    },
    {
        "name": "Mexican peso",
        "symbol": "$",
        "countryName": "Mexico",
        "countryCode": "MX",
        "currencyCode": "MXN",
        "flag": "🇲🇽"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Marshall Islands",
        "countryCode": "MH",
        "currencyCode": "USD",
        "flag": "🇲🇭"
    },
    {
        "name": "denar",
        "symbol": "den",
        "countryName": "Macedonia, The Former Yugoslav Republic of",
        "countryCode": "MK",
        "currencyCode": "MKD",
        "flag": "🇲🇰"
    },
    {
        "name": "West African CFA franc",
        "symbol": "Fr",
        "countryName": "Mali",
        "countryCode": "ML",
        "currencyCode": "XOF",
        "flag": "🇲🇱"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Malta",
        "countryCode": "MT",
        "currencyCode": "EUR",
        "flag": "🇲🇹"
    },
    {
        "name": "Burmese kyat",
        "symbol": "Ks",
        "countryName": "Myanmar",
        "countryCode": "MM",
        "currencyCode": "MMK",
        "flag": "🇲🇲"
    },
    {
        "name": "Mongolian tögrög",
        "symbol": "₮",
        "countryName": "Mongolia",
        "countryCode": "MN",
        "currencyCode": "MNT",
        "flag": "🇲🇳"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Northern Mariana Islands",
        "countryCode": "MP",
        "currencyCode": "USD",
        "flag": "🇲🇵"
    },
    {
        "name": "Mozambican metical",
        "symbol": "MT",
        "countryName": "Mozambique",
        "countryCode": "MZ",
        "currencyCode": "MZN",
        "flag": "🇲🇿"
    },
    {
        "name": "Mauritanian ouguiya",
        "symbol": "UM",
        "countryName": "Mauritania",
        "countryCode": "MR",
        "currencyCode": "MRU",
        "flag": "🇲🇷"
    },
    {
        "name": "Eastern Caribbean dollar",
        "symbol": "$",
        "countryName": "Montserrat",
        "countryCode": "MS",
        "currencyCode": "XCD",
        "flag": "🇲🇸"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Martinique",
        "countryCode": "MQ",
        "currencyCode": "EUR",
        "flag": "🇲🇶"
    },
    {
        "name": "Mauritian rupee",
        "symbol": "₨",
        "countryName": "Mauritius",
        "countryCode": "MU",
        "currencyCode": "MUR",
        "flag": "🇲🇺"
    },
    {
        "name": "Malawian kwacha",
        "symbol": "MK",
        "countryName": "Malawi",
        "countryCode": "MW",
        "currencyCode": "MWK",
        "flag": "🇲🇼"
    },
    {
        "name": "Malaysian ringgit",
        "symbol": "RM",
        "countryName": "Malaysia",
        "countryCode": "MY",
        "currencyCode": "MYR",
        "flag": "🇲🇾"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Mayotte",
        "countryCode": "YT",
        "currencyCode": "EUR",
        "flag": "🇾🇹"
    },
    {
        "name": "Namibian dollar",
        "symbol": "$",
        "countryName": "Namibia",
        "countryCode": "NA",
        "currencyCode": "NAD",
        "flag": "🇳🇦"
    },
    {
        "name": "CFP franc",
        "symbol": "₣",
        "countryName": "New Caledonia",
        "countryCode": "NC",
        "currencyCode": "XPF",
        "flag": "🇳🇨"
    },
    {
        "name": "West African CFA franc",
        "symbol": "Fr",
        "countryName": "Niger",
        "countryCode": "NE",
        "currencyCode": "XOF",
        "flag": "🇳🇪"
    },
    {
        "name": "Australian dollar",
        "symbol": "$",
        "countryName": "Norfolk Island",
        "countryCode": "NF",
        "currencyCode": "AUD",
        "flag": "🇳🇫"
    },
    {
        "name": "Nigerian naira",
        "symbol": "₦",
        "countryName": "Nigeria",
        "countryCode": "NG",
        "currencyCode": "NGN",
        "flag": "🇳🇬"
    },
    {
        "name": "Nicaraguan córdoba",
        "symbol": "C$",
        "countryName": "Nicaragua",
        "countryCode": "NI",
        "currencyCode": "NIO",
        "flag": "🇳🇮"
    },
    {
        "name": "New Zealand dollar",
        "symbol": "$",
        "countryName": "Niue",
        "countryCode": "NU",
        "currencyCode": "NZD",
        "flag": "🇳🇺"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Netherlands",
        "countryCode": "NL",
        "currencyCode": "EUR",
        "flag": "🇳🇱"
    },
    {
        "name": "Norwegian krone",
        "symbol": "kr",
        "countryName": "Norway",
        "countryCode": "NO",
        "currencyCode": "NOK",
        "flag": "🇳🇴"
    },
    {
        "name": "Nepalese rupee",
        "symbol": "₨",
        "countryName": "Nepal",
        "countryCode": "NP",
        "currencyCode": "NPR",
        "flag": "🇳🇵"
    },
    {
        "name": "Australian dollar",
        "symbol": "$",
        "countryName": "Nauru",
        "countryCode": "NR",
        "currencyCode": "AUD",
        "flag": "🇳🇷"
    },
    {
        "name": "New Zealand dollar",
        "symbol": "$",
        "countryName": "New Zealand",
        "countryCode": "NZ",
        "currencyCode": "NZD",
        "flag": "🇳🇿"
    },
    {
        "name": "Omani rial",
        "symbol": "ر.ع.",
        "countryName": "Oman",
        "countryCode": "OM",
        "currencyCode": "OMR",
        "flag": "🇴🇲"
    },
    {
        "name": "Pakistani rupee",
        "symbol": "₨",
        "countryName": "Pakistan",
        "countryCode": "PK",
        "currencyCode": "PKR",
        "flag": "🇵🇰"
    },
    {
        "name": "Panamanian balboa",
        "symbol": "B/.",
        "countryName": "Panama",
        "countryCode": "PA",
        "currencyCode": "PAB",
        "flag": "🇵🇦"
    },
    {
        "name": "New Zealand dollar",
        "symbol": "$",
        "countryName": "Pitcairn",
        "countryCode": "PN",
        "currencyCode": "NZD",
        "flag": "🇵🇳"
    },
    {
        "name": "Peruvian sol",
        "symbol": "S/.",
        "countryName": "Peru",
        "countryCode": "PE",
        "currencyCode": "PEN",
        "flag": "🇵🇪"
    },
    {
        "name": "Philippine peso",
        "symbol": "₱",
        "countryName": "Philippines",
        "countryCode": "PH",
        "currencyCode": "PHP",
        "flag": "🇵🇭"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Palau",
        "countryCode": "PW",
        "currencyCode": "USD",
        "flag": "🇵🇼"
    },
    {
        "name": "Papua New Guinean kina",
        "symbol": "K",
        "countryName": "Papua New Guinea",
        "countryCode": "PG",
        "currencyCode": "PGK",
        "flag": "🇵🇬"
    },
    {
        "name": "Polish złoty",
        "symbol": "zł",
        "countryName": "Poland",
        "countryCode": "PL",
        "currencyCode": "PLN",
        "flag": "🇵🇱"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Puerto Rico",
        "countryCode": "PR",
        "currencyCode": "USD",
        "flag": "🇵🇷"
    },
    {
        "name": "North Korean won",
        "symbol": "₩",
        "countryName": "Korea, Democratic People'S Republic of",
        "countryCode": "KP",
        "currencyCode": "KPW",
        "flag": "🇰🇵"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Portugal",
        "countryCode": "PT",
        "currencyCode": "EUR",
        "flag": "🇵🇹"
    },
    {
        "name": "Paraguayan guaraní",
        "symbol": "₲",
        "countryName": "Paraguay",
        "countryCode": "PY",
        "currencyCode": "PYG",
        "flag": "🇵🇾"
    },
    {
        "name": "Egyptian pound",
        "symbol": "E£",
        "countryName": "Palestinian Territory, Occupied",
        "countryCode": "PS",
        "currencyCode": "EGP",
        "flag": "🇵🇸"
    },
    {
        "name": "CFP franc",
        "symbol": "₣",
        "countryName": "French Polynesia",
        "countryCode": "PF",
        "currencyCode": "XPF",
        "flag": "🇵🇫"
    },
    {
        "name": "Qatari riyal",
        "symbol": "ر.ق",
        "countryName": "Qatar",
        "countryCode": "QA",
        "currencyCode": "QAR",
        "flag": "🇶🇦"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Reunion",
        "countryCode": "RE",
        "currencyCode": "EUR",
        "flag": "🇷🇪"
    },
    {
        "name": "Romanian leu",
        "symbol": "lei",
        "countryName": "Romania",
        "countryCode": "RO",
        "currencyCode": "RON",
        "flag": "🇷🇴"
    },
    {
        "name": "Russian ruble",
        "symbol": "₽",
        "countryName": "Russian Federation",
        "countryCode": "RU",
        "currencyCode": "RUB",
        "flag": "🇷🇺"
    },
    {
        "name": "Rwandan franc",
        "symbol": "Fr",
        "countryName": "RWANDA",
        "countryCode": "RW",
        "currencyCode": "RWF",
        "flag": "🇷🇼"
    },
    {
        "name": "Saudi riyal",
        "symbol": "ر.س",
        "countryName": "Saudi Arabia",
        "countryCode": "SA",
        "currencyCode": "SAR",
        "flag": "🇸🇦"
    },
    {
        "name": "Sudanese pound",
        "symbol": "",
        "countryName": "Sudan",
        "countryCode": "SD",
        "currencyCode": "SDG",
        "flag": "🇸🇩"
    },
    {
        "name": "West African CFA franc",
        "symbol": "Fr",
        "countryName": "Senegal",
        "countryCode": "SN",
        "currencyCode": "XOF",
        "flag": "🇸🇳"
    },
    {
        "name": "Singapore dollar",
        "symbol": "$",
        "countryName": "Singapore",
        "countryCode": "SG",
        "currencyCode": "SGD",
        "flag": "🇸🇬"
    },
    {
        "name": "Saint Helena pound",
        "symbol": "£",
        "countryName": "South Georgia and the South Sandwich Islands",
        "countryCode": "GS",
        "currencyCode": "SHP",
        "flag": "🇬🇸"
    },
    {
        "name": "krone",
        "symbol": "kr",
        "countryName": "Svalbard and Jan Mayen",
        "countryCode": "SJ",
        "currencyCode": "NOK",
        "flag": "🇸🇯"
    },
    {
        "name": "Solomon Islands dollar",
        "symbol": "$",
        "countryName": "Solomon Islands",
        "countryCode": "SB",
        "currencyCode": "SBD",
        "flag": "🇸🇧"
    },
    {
        "name": "Sierra Leonean leone",
        "symbol": "Le",
        "countryName": "Sierra Leone",
        "countryCode": "SL",
        "currencyCode": "SLL",
        "flag": "🇸🇱"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "El Salvador",
        "countryCode": "SV",
        "currencyCode": "USD",
        "flag": "🇸🇻"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "San Marino",
        "countryCode": "SM",
        "currencyCode": "EUR",
        "flag": "🇸🇲"
    },
    {
        "name": "Somali shilling",
        "symbol": "Sh",
        "countryName": "Somalia",
        "countryCode": "SO",
        "currencyCode": "SOS",
        "flag": "🇸🇴"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Saint Pierre and Miquelon",
        "countryCode": "PM",
        "currencyCode": "EUR",
        "flag": "🇵🇲"
    },
    {
        "name": "São Tomé and Príncipe dobra",
        "symbol": "Db",
        "countryName": "Sao Tome and Principe",
        "countryCode": "ST",
        "currencyCode": "STN",
        "flag": "🇸🇹"
    },
    {
        "name": "Surinamese dollar",
        "symbol": "$",
        "countryName": "Suriname",
        "countryCode": "SR",
        "currencyCode": "SRD",
        "flag": "🇸🇷"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Slovakia",
        "countryCode": "SK",
        "currencyCode": "EUR",
        "flag": "🇸🇰"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Slovenia",
        "countryCode": "SI",
        "currencyCode": "EUR",
        "flag": "🇸🇮"
    },
    {
        "name": "Swedish krona",
        "symbol": "kr",
        "countryName": "Sweden",
        "countryCode": "SE",
        "currencyCode": "SEK",
        "flag": "🇸🇪"
    },
    {
        "name": "Swazi lilangeni",
        "symbol": "L",
        "countryName": "Swaziland",
        "countryCode": "SZ",
        "currencyCode": "SZL",
        "flag": "🇸🇿"
    },
    {
        "name": "Seychellois rupee",
        "symbol": "₨",
        "countryName": "Seychelles",
        "countryCode": "SC",
        "currencyCode": "SCR",
        "flag": "🇸🇨"
    },
    {
        "name": "Syrian pound",
        "symbol": "£",
        "countryName": "Syrian Arab Republic",
        "countryCode": "SY",
        "currencyCode": "SYP",
        "flag": "🇸🇾"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Turks and Caicos Islands",
        "countryCode": "TC",
        "currencyCode": "USD",
        "flag": "🇹🇨"
    },
    {
        "name": "Central African CFA franc",
        "symbol": "Fr",
        "countryName": "Chad",
        "countryCode": "TD",
        "currencyCode": "XAF",
        "flag": "🇹🇩"
    },
    {
        "name": "West African CFA franc",
        "symbol": "Fr",
        "countryName": "Togo",
        "countryCode": "TG",
        "currencyCode": "XOF",
        "flag": "🇹🇬"
    },
    {
        "name": "Thai baht",
        "symbol": "฿",
        "countryName": "Thailand",
        "countryCode": "TH",
        "currencyCode": "THB",
        "flag": "🇹🇭"
    },
    {
        "name": "Tajikistani somoni",
        "symbol": "ЅМ",
        "countryName": "Tajikistan",
        "countryCode": "TJ",
        "currencyCode": "TJS",
        "flag": "🇹🇯"
    },
    {
        "name": "New Zealand dollar",
        "symbol": "$",
        "countryName": "Tokelau",
        "countryCode": "TK",
        "currencyCode": "NZD",
        "flag": "🇹🇰"
    },
    {
        "name": "Turkmenistan manat",
        "symbol": "m",
        "countryName": "Turkmenistan",
        "countryCode": "TM",
        "currencyCode": "TMT",
        "flag": "🇹🇲"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Timor-Leste",
        "countryCode": "TL",
        "currencyCode": "USD",
        "flag": "🇹🇱"
    },
    {
        "name": "Tongan paʻanga",
        "symbol": "T$",
        "countryName": "Tonga",
        "countryCode": "TO",
        "currencyCode": "TOP",
        "flag": "🇹🇴"
    },
    {
        "name": "Trinidad and Tobago dollar",
        "symbol": "$",
        "countryName": "Trinidad and Tobago",
        "countryCode": "TT",
        "currencyCode": "TTD",
        "flag": "🇹🇹"
    },
    {
        "name": "Tunisian dinar",
        "symbol": "د.ت",
        "countryName": "Tunisia",
        "countryCode": "TN",
        "currencyCode": "TND",
        "flag": "🇹🇳"
    },
    {
        "name": "Turkish lira",
        "symbol": "₺",
        "countryName": "Turkey",
        "countryCode": "TR",
        "currencyCode": "TRY",
        "flag": "🇹🇷"
    },
    {
        "name": "Australian dollar",
        "symbol": "$",
        "countryName": "Tuvalu",
        "countryCode": "TV",
        "currencyCode": "AUD",
        "flag": "🇹🇻"
    },
    {
        "name": "New Taiwan dollar",
        "symbol": "$",
        "countryName": "Taiwan, Province of China",
        "countryCode": "TW",
        "currencyCode": "TWD",
        "flag": "🇹🇼"
    },
    {
        "name": "Tanzanian shilling",
        "symbol": "Sh",
        "countryName": "Tanzania, United Republic of",
        "countryCode": "TZ",
        "currencyCode": "TZS",
        "flag": "🇹🇿"
    },
    {
        "name": "Ugandan shilling",
        "symbol": "Sh",
        "countryName": "Uganda",
        "countryCode": "UG",
        "currencyCode": "UGX",
        "flag": "🇺🇬"
    },
    {
        "name": "Ukrainian hryvnia",
        "symbol": "₴",
        "countryName": "Ukraine",
        "countryCode": "UA",
        "currencyCode": "UAH",
        "flag": "🇺🇦"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "United States Minor Outlying Islands",
        "countryCode": "UM",
        "currencyCode": "USD",
        "flag": "🇺🇲"
    },
    {
        "name": "Uruguayan peso",
        "symbol": "$",
        "countryName": "Uruguay",
        "countryCode": "UY",
        "currencyCode": "UYU",
        "flag": "🇺🇾"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "United States",
        "countryCode": "US",
        "currencyCode": "USD",
        "flag": "🇺🇸"
    },
    {
        "name": "Uzbekistani soʻm",
        "symbol": "so'm",
        "countryName": "Uzbekistan",
        "countryCode": "UZ",
        "currencyCode": "UZS",
        "flag": "🇺🇿"
    },
    {
        "name": "Euro",
        "symbol": "€",
        "countryName": "Holy See (Vatican City State)",
        "countryCode": "VA",
        "currencyCode": "EUR",
        "flag": "🇻🇦"
    },
    {
        "name": "Eastern Caribbean dollar",
        "symbol": "$",
        "countryName": "Saint Vincent and the Grenadines",
        "countryCode": "VC",
        "currencyCode": "XCD",
        "flag": "🇻🇨"
    },
    {
        "name": "Venezuelan bolívar soberano",
        "symbol": "Bs.S.",
        "countryName": "Venezuela",
        "countryCode": "VE",
        "currencyCode": "VES",
        "flag": "🇻🇪"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Virgin Islands, British",
        "countryCode": "VG",
        "currencyCode": "USD",
        "flag": "🇻🇬"
    },
    {
        "name": "United States dollar",
        "symbol": "$",
        "countryName": "Virgin Islands, U.S.",
        "countryCode": "VI",
        "currencyCode": "USD",
        "flag": "🇻🇮"
    },
    {
        "name": "Vietnamese đồng",
        "symbol": "₫",
        "countryName": "Viet Nam",
        "countryCode": "VN",
        "currencyCode": "VND",
        "flag": "🇻🇳"
    },
    {
        "name": "Vanuatu vatu",
        "symbol": "Vt",
        "countryName": "Vanuatu",
        "countryCode": "VU",
        "currencyCode": "VUV",
        "flag": "🇻🇺"
    },
    {
        "name": "CFP franc",
        "symbol": "₣",
        "countryName": "Wallis and Futuna",
        "countryCode": "WF",
        "currencyCode": "XPF",
        "flag": "🇼🇫"
    },
    {
        "name": "Samoan tālā",
        "symbol": "T",
        "countryName": "Samoa",
        "countryCode": "WS",
        "currencyCode": "WST",
        "flag": "🇼🇸"
    },
    {
        "name": "Yemeni rial",
        "symbol": "﷼",
        "countryName": "Yemen",
        "countryCode": "YE",
        "currencyCode": "YER",
        "flag": "🇾🇪"
    },
    {
        "name": "South African rand",
        "symbol": "R",
        "countryName": "South Africa",
        "countryCode": "ZA",
        "currencyCode": "ZAR",
        "flag": "🇿🇦"
    },
    {
        "name": "Zambian kwacha",
        "symbol": "ZK",
        "countryName": "Zambia",
        "countryCode": "ZM",
        "currencyCode": "ZMW",
        "flag": "🇿🇲"
    },
    {
        "name": "Botswana pula",
        "symbol": "P",
        "countryName": "Zimbabwe",
        "countryCode": "ZW",
        "currencyCode": "BWP",
        "flag": "🇿🇼"
    }
];