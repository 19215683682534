export const listings = [
    {
        code: 'PrivBoat-ELN-B',
        title: 'Private Boat Island Hopping Tour B in El Nido',
        slug: 'private-boat-island-hopping-tour-b-in-el-nido',
        origin: 'ELN',
        destination: 'ELN',
        content: {
            sched: ['9:00 AM'],
            duration: '8h',
            pickup: ['El Nido Beach Boat Dock', 'El Nido Town Center', 'Hotels in Corong-corong Area'],
            dropoff: ['El Nido Beach Boat Dock'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '8:30am -9:00am': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Island Hopping Tour A - visits 5 Islands',
                    '5:00 PM': 'Drop off at El Nido Boat Dock',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)', 'Entrance to Big Lagoon', '5 Island Destinations'],
                exclusions: ['El Nido EcoTourism Development Fee(PHP 400 per person)', 'Dinner', 'Hotel Drop Off(You can ride tricycle going to your hotel)', 'Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: 'Book your Private Boat Island Hopping Tour B in El Nido. Discover stunning islands and crystal-clear waters. Book your exclusive adventure today!',
        },
        listing_type: 'PRIVATE_BOAT_TOUR',
        ratePlan: {
            base: 225,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 12,
        images: ['ELN-B'],
        updatedAt: '2024-08-05T12:30:50.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-SVC-SAB',
        title: 'Private Van Transfer from San Vicente to Sabang',
        slug: 'private-van-transfer-from-san-vicente-to-sabang',
        origin: 'SVC',
        destination: 'SAB',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel within San Vicente Town Center', 'Hotel in Alimanguan'],
            dropoff: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            markdown_content: {
                price: 180,
                duration: 'One day',
                itinerary: {
                    '07:00 AM': 'Pickup from your hotel within San Vicente Town Center.',
                    '07:15 AM': 'Depart from San Vicente. Begin your journey through the scenic roads leading to Sabang, enjoying the lush landscapes of Palawan.',
                    '10:30 AM': 'Short comfort break. Take advantage of this time to stretch your legs and grab a coffee or snacks (at your own expense).',
                    '01:00 PM': 'Lunch Break at a local restaurant along the way. Discover local flavors and delicacies of Palawan (cost not included in the package).',
                    '03:00 PM': 'Resume the journey to Sabang, with the second leg offering views of coastal roads and rural life.',
                    '05:00 PM': 'Arrival at your Hotel near Sabang Beach. Check-in and end of service.',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional and courteous driver', 'Pickup from your hotel within San Vicente Town Center', 'Dropoff at Hotel near Sabang Beach', 'Fuel surcharge'],
                exclusions: ['Meals and personal expenses', 'Tips and gratuities (optional)', 'Any additional stops or detours from the direct route'],
                importantNotes: [
                    'This is a private service; only your group will participate.',
                    'Please be ready at the hotel lobby 10 minutes before your scheduled pickup time.',
                    'The journey might take longer due to traffic or weather conditions.',
                    'Ensure you have all necessary travel documents for hotel check-ins.',
                ],
                bookingPolicy: 'Full payment is required at the time of booking to secure your transfer. Cancellations must be made at least 24 hours before the scheduled pickup time for a full refund. Cancellations made less than 24 hours before pickup time will not be refunded.',
            },
            seo_description: 'Book your Private Van Transfer from San Vicente to Sabang. Enjoy a comfortable and convenient ride. Book your hassle-free transfer today!',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 170,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 9,
        images: ['UR'],
        updatedAt: '2024-08-05T12:31:37.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'JT-HB',
        title: 'Joining Honda Bay Island Hopping Boat Tour in Puerto Princesa',
        slug: 'joining-honda-bay-island-hopping-boat-tour-in-puerto-princesa',
        origin: 'PPS',
        destination: 'PPS',
        content: {
            sched: ['8:00 AM'],
            duration: '8h',
            pickup: ['Hotel in Puerto Princesa', 'Astoria Palawan'],
            dropoff: ['Hotel in Puerto Princesa', 'Astoria Palawan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '7:30 AM- 8:00 AM': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Honda Bay Island Hopping.\nSwimming and Snorkeling',
                    '4:00 PM': 'Tour ends. Drop off at your hotel',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)', 'Hotel Drop Off'],
                exclusions: ['Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental', 'Puerto Princesa Ecotourism Development Fee(PHP 150 per person)'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: 'Book your Joining Honda Bay Island Hopping Boat Tour in Puerto Princesa. Explore beautiful islands effortlessly. Book your adventure today!',
        },
        listing_type: 'JOINING_TOUR',
        ratePlan: {
            base: 33,
            extra: 33,
            inc: 1,
        },
        provider_id: 'DSPal',
        reviewCount: 19,
        images: ['HB'],
        updatedAt: '2024-08-05T12:33:35.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPUR',
        title: 'Private Puerto Princesa Underground River Tour',
        slug: 'private-puerto-princesa-underground-river-tour',
        origin: 'PPS',
        destination: 'ELN',
        content: {
            sched: ['6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM'],
            duration: '8h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Palawan'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km of City Proper', 'Astoria Palawan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '6:00 AM': 'Pickup from your hotel within Puerto Princesa City Proper',
                    '8:30 AM': "Arrive at the Puerto Princesa Subterranean River National Park. Enjoy a guided boat tour through the underground river's impressive caves and marvel at its natural beauty.",
                    '11:30 AM': 'Lunch at a local restaurant, featuring a selection of traditional Filipino dishes.',
                    '1:00 PM': 'Departure  back to your hotel in Puerto Princesa. Journey through the picturesque landscapes of Palawan, taking in the lush mountains and quaint towns along the way.',
                    '3:00 PM': 'Arrival at your hotel in Puerto Princesa. End of the Tour',
                },
                inclusions: [
                    'Hotel pickup and drop-off',
                    'Air-conditioned vehicle transfer from Puerto Princesa to El Nido',
                    'Entrance fees to the Puerto Princesa Subterranean River National Park',
                    'Boat ride and guided tour of the Underground River',
                    'Lunch at a local restaurant',
                    'Professional English-speaking guide',
                    'Environmental fee',
                ],
                exclusions: ['Breakfast and dinner', 'Personal expenses', 'Tips for guide and driver (optional)'],
                importantNotes: [
                    'A valid ID is required on the day of the tour.',
                    'Guests are advised to wear comfortable clothing and bring sunscreen, insect repellent, and a hat.',
                    'Children under 8 years old are not recommended to participate in the underground river tour.',
                    'Subject to favorable weather conditions. If canceled due to poor weather, you will be given the option of an alternative date or a full refund.',
                ],
                bookingPolicy: 'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date will be fully refunded, excluding a 10% processing fee. Cancellations made within 7 days of the tour date are non-refundable.',
            },
            seo_description: 'Book your Private Puerto Princesa Underground River Tour. Experience this natural wonder effortlessly. Secure your unforgettable adventure now!',
        },
        listing_type: 'PRIVATE_BOAT_TOUR',
        ratePlan: {
            base: 149,
            extra: 24,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 36,
        images: ['VAN', 'UR'],
        updatedAt: '2024-08-05T11:58:12.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'JT-ELN-D',
        title: 'Joining Island Hopping Tour D in El Nido',
        slug: 'joining-island-hopping-tour-d-in-el-nido',
        origin: 'ELN',
        destination: 'ELN',
        content: {
            sched: ['9:00 AM'],
            duration: '8h',
            pickup: ['El Nido Beach Boat Dock', 'El Nido Town Center', 'Hotels in Corong-corong Area'],
            dropoff: ['El Nido Beach Boat Dock'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '8:30am -9:00am': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Island Hopping Tour D - visits 5 Islands',
                    '5:00 PM': 'Drop off at El Nido Boat Dock',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)'],
                exclusions: ['El Nido EcoTourism Development Fee(PHP 400 per person)', 'Dinner', 'Hotel Drop Off(You can ride tricycle going to your hotel)', 'Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability, weather conditions and price adjustments.',
            },
            seo_description: 'Book your Joining Island Hopping Tour D in El Nido. Explore pristine beaches and hidden lagoons with ease. Book your adventure today!',
        },
        listing_type: 'JOINING_TOUR',
        ratePlan: {
            base: 25.99,
            extra: 25.99,
            inc: 1,
        },
        provider_id: 'DSPal',
        reviewCount: 9,
        images: ['ELN-D'],
        updatedAt: '2024-08-05T12:29:58.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'JT-CTAM',
        title: 'Joining Morning Puerto Princesa City Tour',
        slug: 'joining-morning-puerto-princesa-city-tour',
        origin: 'PPS',
        destination: 'PPS',
        content: {
            sched: ['8:00 AM'],
            duration: '8h',
            pickup: ['Hotel in Puerto Princesa', 'Astoria Palawan'],
            dropoff: ['Hotel in Puerto Princesa', 'Astoria Palawan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '7:30 AM - 8:00 AM': 'Pickup at your hotel or meetup at location. ',
                    '8:30 AM': 'City Tour Destinations',
                    '12:00 PM': 'Tour ends. Drop off at your hotel',
                },
                inclusions: ['Hotel Pickup(within Pickup Area)', 'Hotel Drop Off', 'Light Snacks'],
                exclusions: ['Tips and gratuities'],
                importantNotes: ['Please wear sunscreens'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: 'Book your Joining Morning Puerto Princesa City Tour. Discover cultural landmarks and historical sites with ease. Book your tour today!',
        },
        listing_type: 'JOINING_TOUR',
        ratePlan: {
            base: 15,
            extra: 15,
            inc: 1,
        },
        provider_id: 'DSPal',
        reviewCount: 19,
        images: ['CT'],
        updatedAt: '2024-08-05T12:34:49.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPUR-SAB-ELN',
        title: 'Private Underground River Tour from Sabang to El Nido',
        slug: 'private-underground-river-tour-from-sabang-to-el-nido',
        origin: 'SAB',
        destination: 'ELN',
        content: {
            sched: ['6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM'],
            duration: '8h',
            pickup: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            dropoff: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '6:00 AM': 'Pickup from your hotel near Sabang Beach.',
                    '7:30 AM': 'Arrival at the Puerto Princesa Subterranean River National Park. Briefing and preparation for the Underground River tour.',
                    '8:00 AM': 'Start of the Underground River Tour. Enjoy a guided boat journey through the fascinating cave system, marveling at the stunning limestone formations and rich biodiversity.',
                    '10:00 AM': 'Tour concludes. Depart for a scenic journey to El Nido, with a stop for lunch at a local restaurant (lunch included).',
                    '1:00 PM': 'Continue the journey to El Nido, passing by picturesque villages and landscapes.',
                    '3:00 PM': 'Short break for refreshments and to stretch your legs.',
                    '5:30 PM': 'Arrive at El Nido Bus Terminal. End of tour.',
                },
                inclusions: ['Hotel pickup and drop-off', 'Transportation by air-conditioned vehicle', 'Underground River Tour with boat and guide', 'All entrance and environmental fees', 'Lunch at a local restaurant', 'Refreshment breaks'],
                exclusions: ['Breakfast and dinner', 'Personal expenses', 'Tips and gratuities (optional)'],
                importantNotes: [
                    'Please bring your passport or any valid ID for the Underground River permit.',
                    'Wear comfortable clothes and shoes suitable for walking.',
                    'Bring sun protection, insect repellent, and a waterproof bag for electronics.',
                    'The itinerary may be subject to change due to weather conditions and other unforeseen situations.',
                ],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made within 7 days of the tour date are non-refundable. Cancellations made 8 to 14 days before the tour date will incur a 50% cancellation fee. For cancellations made more than 14 days before the tour date, a full refund will be provided, excluding any processing fees.',
            },
            seo_description: 'Book your Private Underground River Tour from Sabang to El Nido. Experience a scenic journey through natural wonders. Secure your adventure today!',
        },
        listing_type: 'PRIVATE_TRANSFER_WITH_TOUR',
        ratePlan: {
            base: 290,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 43,
        images: ['VAN', 'UR'],
        updatedAt: '2024-08-05T12:35:40.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'JT-CTPM',
        title: 'Joining Afternoon Puerto Princesa City Tour',
        slug: 'joining-afternoon-puerto-princesa-city-tour',
        origin: 'PPS',
        destination: 'PPS',
        content: {
            sched: ['1:00 PM'],
            duration: '8h',
            pickup: ['Hotel in Puerto Princesa', 'Astoria Palawan'],
            dropoff: ['Hotel in Puerto Princesa', 'Astoria Palawan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '12:30 PM - 1:00 PM': 'Pickup at your hotel or meetup at location. ',
                    '1:00 PM': 'Start of Tour',
                    '4:30 PM - 5:00 PM': 'Tour ends. Hotel Drop Off',
                },
                inclusions: ['Hotel Pickup(within Pickup Area)', 'Hotel Drop Off', 'Light Snacks'],
                exclusions: ['Dinner', 'Tips and gratuities'],
                importantNotes: ['Please wear sunscreen and sunglasses'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: 'Book your Joining Afternoon Puerto Princesa City Tour. Explore landmarks and attractions at your convenience. Book your tour for a memorable afternoon!',
        },
        listing_type: 'JOINING_TOUR',
        ratePlan: {
            base: 15,
            extra: 15,
            inc: 1,
        },
        provider_id: 'DSPal',
        reviewCount: 19,
        images: ['CT'],
        updatedAt: '2024-08-05T12:36:16.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PrivBoat-ELN-D',
        title: 'Private Island Hopping Boat Tour D in El Nido',
        slug: 'private-island-hopping-boat-tour-d-in-el-nido',
        origin: 'ELN',
        destination: 'ELN',
        content: {
            sched: ['9:00 AM'],
            duration: '8h',
            pickup: ['El Nido Beach Boat Dock', 'El Nido Town Center', 'Hotels in Corong-corong Area'],
            dropoff: ['El Nido Beach Boat Dock'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '8:30am -9:00am': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Island Hopping Tour D- visits 5 Islands',
                    '5:00 PM': 'Drop off at El Nido Boat Dock',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)', '5 Islands to visit', 'Private Boat'],
                exclusions: ['El Nido EcoTourism Development Fee(PHP 400 per person)', 'Dinner', 'Hotel Drop Off(You can ride tricycle going to your hotel)', 'Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability, weather conditions and price adjustments.',
            },
            seo_description: 'Book your Private Island Hopping Boat Tour D in El Nido. Enjoy secluded beaches and turquoise waters on your exclusive adventure. Book now!',
        },
        listing_type: 'PRIVATE_BOAT_TOUR',
        ratePlan: {
            base: 290,
            extra: 40,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 19,
        images: ['ELN-D'],
        updatedAt: '2024-08-05T12:40:18.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PrivBoat-ELN-C',
        title: 'Private Island Hopping Boat Tour C in El Nido',
        slug: 'private-island-hopping-boat-tour-c-in-el-nido',
        origin: 'ELN',
        destination: 'ELN',
        content: {
            sched: ['9:00 AM'],
            duration: '8h',
            pickup: ['El Nido Beach Boat Dock', 'El Nido Town Center', 'Hotels in Corong-corong Area'],
            dropoff: ['El Nido Beach Boat Dock'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '8:30am -9:00am': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Island Hopping Tour C - visits 5 Islands',
                    '5:00 PM': 'Drop off at El Nido Boat Dock',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)', 'Entrance to Big Lagoon'],
                exclusions: ['El Nido EcoTourism Development Fee(PHP 400 per person)', 'Dinner', 'Hotel Drop Off(You can ride tricycle going to your hotel)', 'Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: 'Book your Private Island Hopping Boat Tour C in El Nido. Explore stunning islands and hidden coves in luxury and privacy. Book your adventure today!',
        },
        listing_type: 'PRIVATE_BOAT_TOUR',
        ratePlan: {
            base: 240,
            extra: 40,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 13,
        images: ['ELN-C'],
        updatedAt: '2024-08-05T12:41:18.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPUR-PPS-ELN',
        title: 'Private Underground River Tour from Puerto Princesa to El Nido',
        slug: 'private-underground-river-tour-from-puerto-princesa-to-el-nido',
        origin: 'PPS',
        destination: 'ELN',
        content: {
            sched: ['6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM'],
            duration: '8h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Palawan'],
            dropoff: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '6:00 AM': 'Pickup from your hotel within Puerto Princesa City Proper',
                    '8:30 AM': "Arrive at the Puerto Princesa Subterranean River National Park. Enjoy a guided boat tour through the underground river's impressive caves and marvel at its natural beauty.",
                    '11:30 AM': 'Lunch at a local restaurant, featuring a selection of traditional Filipino dishes.',
                    '1:00 PM': 'Departure for El Nido. Journey through the picturesque landscapes of Palawan, taking in the lush mountains and quaint towns along the way.',
                    '5:00 PM': 'Quick rest stop. Opportunity to stretch your legs and enjoy some local snacks.',
                    '6:30 PM': 'Arrival at El Nido Bus Terminal. End of tour.',
                },
                inclusions: [
                    'Hotel pickup and drop-off',
                    'Air-conditioned vehicle transfer from Puerto Princesa to El Nido',
                    'Entrance fees to the Puerto Princesa Subterranean River National Park',
                    'Boat ride and guided tour of the Underground River',
                    'Lunch at a local restaurant',
                    'Professional English-speaking guide',
                    'Environmental fee',
                ],
                exclusions: ['Breakfast and dinner', 'Personal expenses', 'Tips for guide and driver (optional)'],
                importantNotes: [
                    'A valid ID is required on the day of the tour.',
                    'Guests are advised to wear comfortable clothing and bring sunscreen, insect repellent, and a hat.',
                    'Children under 8 years old are not recommended to participate in the underground river tour.',
                    'Subject to favorable weather conditions. If canceled due to poor weather, you will be given the option of an alternative date or a full refund.',
                ],
                bookingPolicy: 'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date will be fully refunded, excluding a 10% processing fee. Cancellations made within 7 days of the tour date are non-refundable.',
            },
            seo_description: 'Book your Private Underground River Tour from Puerto Princesa to El Nido. Embark on a scenic journey through natural wonders. Secure your adventure today!',
        },
        listing_type: 'PRIVATE_TRANSFER_WITH_TOUR',
        ratePlan: {
            base: 220,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 50,
        images: ['VAN', 'UR'],
        updatedAt: '2024-08-05T12:42:37.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPS-ELN',
        title: 'Private Van Transfer from Puerto Princesa to El Nido',
        slug: 'private-van-transfer-from-puerto-princesa-to-el-nido',
        origin: 'PPS',
        destination: 'ELN',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            dropoff: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            markdown_content: {
                price: 150,
                duration: 'One day',
                itinerary: {
                    '7:00 AM': 'Pickup from your hotel within Puerto Princesa City Proper.',
                    '7:30 AM': 'Departure from Puerto Princesa. Begin your journey towards El Nido, passing through scenic rural landscapes and quaint towns.',
                    '10:30 AM': 'Short comfort break at a designated stopover. Refreshment facilities available.',
                    '1:00 PM': 'Lunch stop at a local restaurant (meal not included in the package price).',
                    '3:00 PM': 'Resume journey to El Nido.',
                    '6:00 PM': 'Arrive at El Nido Bus Terminal. End of service.',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional driver', 'Fuel surcharge', 'Pickup from hotel within Puerto Princesa City Proper'],
                exclusions: ['Meals and beverages', 'Tips and gratuities', 'Personal expenses'],
                importantNotes: [
                    'This transfer is a private service; only your group will participate.',
                    'Pickup is exclusively within Puerto Princesa City Proper hotels. For accommodations outside this area, please contact us for arrangements.',
                    'Luggage allowance is 1 standard-sized suitcase and 1 small carry-on bag per person. Please notify us in advance if carrying excess luggage.',
                    'The journey time is approximate and may vary depending on traffic and weather conditions.',
                    'Ensure you have sufficient personal insurance coverage for this trip.',
                ],
                bookingPolicy:
                    'Full payment is required at the time of booking. For cancellations made more than 24 hours before scheduled departure, a full refund will be issued. Cancellations made less than 24 hours before departure will not be refunded. Changes to the booking are subject to availability and may incur additional charges.',
            },
            seo_description: 'Book your Private Van Transfer from Puerto Princesa to El Nido. Enjoy a comfortable and convenient journey through scenic landscapes. Book your transfer today!',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 135,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 49,
        images: ['VAN'],
        updatedAt: '2024-08-05T17:03:01.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PrivBoat-HB',
        title: 'Private Honda Bay Island Hopping Boat Tour in Puerto Princesa',
        slug: 'private-honda-bay-island-hopping-boat-tour-in-puerto-princesa',
        origin: 'PPS',
        destination: 'PPS',
        content: {
            sched: ['8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM'],
            duration: '8h',
            pickup: ['Hotel in Puerto Princesa', 'Astoria Palawan'],
            dropoff: ['Hotel in Puerto Princesa', 'Astoria Palawan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '7:30 AM- 8:00 AM': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Honda Bay Island Hopping.\nSwimming and Snorkeling',
                    '4:00 PM': 'Tour ends. Drop off at your hotel',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)', 'Hotel Drop Off'],
                exclusions: ['Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental', 'Puerto Princesa Ecotourism Development Fee(PHP 150 per person)'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: '\nOnline Booking for Private Honda Bay Island Hopping Boat Tour in Puerto Princesa. Experience exclusive island exploration. Book your private adventure now!',
        },
        listing_type: 'PRIVATE_BOAT_TOUR',
        ratePlan: {
            base: 159,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 22,
        images: ['HB'],
        updatedAt: '2024-08-05T12:34:18.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-ELN-PBT',
        title: 'Private Van Transfer from El Nido to Port Barton',
        slug: 'private-van-transfer-from-el-nido-to-port-barton',
        origin: 'ELN',
        destination: 'PBT',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            dropoff: ['Port Barton Terminal', 'Hotel near Port Barton Village'],
            markdown_content: {
                price: 130,
                duration: 'One day',
                itinerary: {
                    '07:00': 'Meet your driver at El Nido Bus Terminal for a prompt departure.',
                    '07:15': 'Depart from El Nido, beginning your journey towards Port Barton.',
                    '10:00': 'Short Rest Stop: Enjoy a brief pause in your journey, perfect for stretching your legs and grabbing some refreshments (at your own expense).',
                    '12:00': 'Continue your journey towards Port Barton, taking in the scenic views along the way.',
                    '13:30': 'Arrival at Port Barton Terminal: Conclude your transfer and start exploring the serene beauty of Port Barton.',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional and courteous driver', 'Fuel surcharge'],
                exclusions: ['Meals and drinks', 'Personal expenses', 'Tips for the driver (optional)'],
                importantNotes: [
                    'Please be ready at the pickup location at least 15 minutes before your scheduled departure.',
                    'The journey duration may vary depending on traffic and weather conditions.',
                    'Luggage allowance: 2 standard size suitcases and 1 carry-on per passenger. Please inform us in advance if you have additional luggage.',
                ],
                bookingPolicy: 'Booking should be made at least 24 hours before the scheduled departure. For cancellations, please inform us at least 48 hours in advance for a full refund. Late cancellations and no-shows will be non-refundable.',
            },
            seo_description: 'Book your Private Van Transfer from El Nido to Port Barton. Enjoy a scenic journey with comfort and convenience. Book your private transfer today!',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 125,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 17,
        images: ['VAN'],
        updatedAt: '2024-08-05T12:49:04.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'SV-PPS-ELN',
        title: 'Shared Minivan Transfer from Puerto Princesa to El Nido',
        slug: 'shared-minivan-transfer-from-puerto-princesa-to-el-nido',
        origin: 'PPS',
        destination: 'ELN',
        content: {
            sched: ['6:00 AM', '7:00 AM', '9:00 AM', '11:00 AM', '1:30 PM', '3:00 PM', '5:00 PM', '7:30 PM'],
            duration: '5h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            dropoff: ['El Nido Bus Terminal'],
            markdown_content: {
                price: 15,
                duration: 'One day',
                itinerary: {
                    '6:00 AM': 'Pickup from your hotel within City Proper of Puerto Princesa.',
                    '6:30 AM': 'Departure from Puerto Princesa. Begin your journey towards El Nido, passing by scenic routes and local villages.',
                    '9:00 AM': 'Short rest stop. Take a moment to stretch your legs, use restrooms or buy snacks.',
                    '12:00 PM': 'Lunch break at a local restaurant (cost not included in the package). Enjoy traditional Filipino dishes and refresh yourself for the remainder of the journey.',
                    '2:00 PM': 'Resume travel to El Nido, absorbing the lush landscapes and picturesque views of Palawan.',
                    '4:00 PM': 'Arrive at El Nido Bus Terminal. End of service.',
                },
                inclusions: ['Air-conditioned minivan transfer', 'Professional and courteous driver', 'Pickup from hotel within Puerto Princesa City Proper', 'Drop-off at El Nido Bus Terminal'],
                exclusions: ['Meals and beverages', 'Personal expenses', 'Tips and gratuities'],
                importantNotes: [
                    'Please be ready at the hotel lobby at least 15 minutes before pickup time.',
                    'Wear comfortable clothing and bring sunscreen, sunglasses, and water to stay hydrated.',
                    'Lunch stop is included in the itinerary, but the cost of the meal is not covered.',
                    'Exact pickup and arrival times may vary due to traffic and weather conditions.',
                ],
                bookingPolicy: 'Full payment is required at the time of booking. Cancellations made at least 24 hours before the scheduled departure time will be refunded in full. No refunds will be issued for cancellations made within 24 hours of the travel date or for no-shows.',
            },
            seo_description: 'Book your Shared Minivan Transfer from Puerto Princesa to El Nido. Enjoy an affordable and convenient way to travel between these scenic destinations. Book your seat today!',
        },
        listing_type: 'SHARED_TRANSFER',
        ratePlan: {
            base: 14,
            extra: 14,
            inc: 1,
        },
        provider_id: 'Zion',
        reviewCount: 48,
        images: ['VAN'],
        updatedAt: '2024-08-05T12:58:35.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'Zion',
            name: 'Zion Transport',
            email: null,
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-SAB-ELN',
        title: 'Private Van Transfer from Sabang to El Nido',
        slug: 'private-van-transfer-from-sabang-to-el-nido',
        origin: 'SAB',
        destination: 'ELN',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            dropoff: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            markdown_content: {
                price: 190,
                duration: 'One Day',
                itinerary: {
                    '07:00 AM': 'Pick up from your hotel near Sabang Beach.',
                    '07:30 AM': 'Begin your journey towards El Nido, passing through rural landscapes and local villages.',
                    '10:30 AM': 'Short stop for refreshments and restroom break (snacks at your own expense).',
                    '01:00 PM': 'Lunch break at a local restaurant (cost not included in the package).',
                    '03:00 PM': 'Continue your journey to El Nido, enjoy scenic views of the Palawan countryside.',
                    '06:00 PM': 'Arrive at El Nido Bus Terminal.',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional and licensed driver', 'Pickup from hotel near Sabang Beach', 'Dropoff at El Nido Bus Terminal', 'Fuel surcharge'],
                exclusions: ['Meals and snacks', 'Gratuities (optional)', 'Personal expenses', 'Restroom fees'],
                importantNotes: [
                    'Please be ready at the hotel lobby at least 15 minutes before the scheduled pickup.',
                    'The exact pickup time may vary depending on weather and traffic conditions.',
                    'Only one standard luggage per passenger due to space limitations. Please contact us in advance for extra luggage requests.',
                    'Wearing a mask inside the vehicle is advised for your safety.',
                    'This is a private transfer, not a shared ride. The van will be exclusively for your group.',
                ],
                bookingPolicy: 'Full payment is required to secure your booking. Cancellations made more than 48 hours before the scheduled pickup will receive a full refund. Cancellations made less than 48 hours before the scheduled pickup will not be refunded. No-shows will also not be refunded.',
            },
            seo_description: 'Book your Private Van Transfer from Sabang to El Nido. Experience convenience and comfort on your journey between these beautiful destinations. Book your transfer today!',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 155,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 48,
        images: ['VAN'],
        updatedAt: '2024-08-05T12:57:53.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'SV-ELN-PBT',
        title: 'Shared Minivan Transfer from El Nido to Port Barton',
        slug: 'shared-minivan-transfer-from-el-nido-to-port-barton',
        origin: 'ELN',
        destination: 'PBT',
        content: {
            sched: ['8:00 AM', '1:00 PM', '5:00 PM'],
            duration: '5h',
            pickup: ['El Nido Bus Terminal'],
            dropoff: ['Port Barton Terminal'],
            markdown_content: {
                price: 15,
                duration: '4 hours',
                itinerary: {
                    '08:00 AM': 'Depart from El Nido Bus Terminal.',
                    '12:00 PM': 'Arrive at Port Barton Terminal.',
                },
                inclusions: ['Air-conditioned Minivan Transfer', 'Professional Driver', 'Fuel Surcharge'],
                exclusions: ['Personal Expenses', 'Meals and Beverages', 'Tips and Gratitudes'],
                importantNotes: [
                    'Please be at the pickup point 15 minutes before departure time.',
                    'One piece of luggage per passenger is allowed; additional charges may apply for extra baggage.',
                    'The travel time may vary depending on road conditions or unforeseen circumstances.',
                    'Wearing a mask during the trip is advisable for personal protection.',
                    'Minivan transfer is subject to cancellation due to severe weather conditions.',
                ],
                bookingPolicy: 'Bookings must be made at least 24 hours before the trip. Cancellations or changes to the booking should be notified at least 12 hours before the departure time for a full refund.',
            },
            seo_description: 'Book your Shared Minivan Transfer from El Nido to Port Barton. Convenient and economical travel option with scenic views. Reserve your seat today!',
        },
        listing_type: 'SHARED_TRANSFER',
        ratePlan: {
            base: 16,
            extra: 16,
            inc: 1,
        },
        provider_id: 'Lexxus',
        reviewCount: 44,
        images: ['VAN'],
        updatedAt: '2024-08-05T12:59:13.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'Lexxus',
            name: 'Lexxus Shuttle',
            email: null,
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPUR-SVC-PPS',
        title: 'Private Underground River Tour from San Vicente to Puerto Princesa',
        slug: 'private-underground-river-tour-from-san-vicente-to-puerto-princesa',
        origin: 'SVC',
        destination: 'PPS',
        content: {
            sched: ['4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM'],
            duration: '8h',
            pickup: ['Hotel within San Vicente Town Center', 'Hotel in Alimanguan'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '04:00 AM': 'Hotel pickup from San Vicente Town Center',
                    '06:30 AM': 'Arrival at Sabang Wharf, breakfast at a local restaurant (own expense)',
                    '08:00 AM': 'Proceed to the Puerto Princesa Subterranean River National Park via boat',
                    '09:00 AM': 'Start of the Underground River Tour',
                    '11:00 AM': 'End of the Underground River Tour, free time for photos',
                    '12:00 PM': 'Lunch at a local restaurant',
                    '02:00 PM': 'Departure back to Puerto Princesa',
                    '04:00 PM': 'Short stop at a souvenir shop',
                    '06:00 PM': 'Arrival at Hotel within City Proper',
                },
                inclusions: ['Hotel pickup and drop-off', 'Air-conditioned vehicle', 'Professional guide', 'All entrance fees', 'Boat fees for the underground river', 'Environmental fee', 'Lunch'],
                exclusions: ['Breakfast', 'Personal expenses', 'Gratuities (optional)'],
                importantNotes: [
                    'Passport name, number, expiry, and country is required at time of booking for all participants',
                    'A current valid passport is required on the day of travel',
                    'Not wheelchair accessible',
                    'Children must be accompanied by an adult',
                    'Subject to favorable weather conditions. If canceled due to poor weather, you will be given the option of an alternative date or full refund',
                    'Please wear comfortable walking shoes and bring a hat, sunscreen, and a camera',
                ],
                bookingPolicy: 'Full payment is required at the time of booking. Cancellations made within 7 days of the tour date will incur a 100% cancellation fee. Cancellations made more than 7 days prior to the tour date will receive a full refund.',
            },
            seo_description: 'Book your Private Underground River Tour from San Vicente to Puerto Princesa. Experience a unique adventure with ease and convenience.',
        },
        listing_type: 'PRIVATE_TRANSFER_WITH_TOUR',
        ratePlan: {
            base: 270,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 17,
        images: ['VAN', 'UR'],
        updatedAt: '2024-08-05T16:35:30.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPS-SAB',
        title: 'Private Van Transfer from Puerto Princesa to Sabang',
        slug: 'private-van-transfer-from-puerto-princesa-to-sabang',
        origin: 'PPS',
        destination: 'SAB',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            dropoff: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            markdown_content: {
                price: 90,
                duration: '1 day',
                itinerary: {
                    '7:00 AM': 'Pickup from your hotel within Puerto Princesa City Proper.',
                    '7:10 AM': 'Departure from Puerto Princesa, beginning the journey to Sabang.',
                    '9:00 AM': 'Short stop at a scenic spot for photo opportunities and a stretch break.',
                    '11:00 AM': 'Arrival at Sabang. You may opt to explore the area or dine at a local restaurant for lunch (at your own expense).',
                    '2:00 PM': "Continue enjoying Sabang's offerings at your leisure.",
                    '5:00 PM': 'Check-in at your hotel near Sabang Beach or if you prefer, we can drop you off at a location of your choice within Sabang.',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional and courteous driver', 'Fuel and all road tolls', 'Hotel pickup and drop-off'],
                exclusions: ['Meals and drinks', 'Tips and gratuities (optional)', 'Personal expenses', 'Activities and entrance fees'],
                importantNotes: [
                    'Please be ready at the hotel lobby 15 minutes before departure.',
                    'The duration of transfers is approximate; the exact duration will depend on the time of day and traffic conditions.',
                    'This is a private transfer; only your group will participate.',
                    'Maximum of 8 passengers per van for comfortable and spacious seating.',
                ],
                bookingPolicy:
                    'Full payment is required at the time of booking. For cancellations up to 24 hours before the scheduled transfer, a full refund will be provided. Cancellations within 24 hours of the transfer date are non-refundable. Changes to the booking are subject to availability and must be made at least 24 hours before the originally scheduled departure.',
            },
            seo_description: 'Book your Private Van Transfer from Puerto Princesa to Sabang. Enjoy a comfortable and hassle-free journey with our reliable service.',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 99,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 38,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:35:59.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PBT-PPS',
        title: 'Private Van Transfer from Port Barton to Puerto Princesa',
        slug: 'private-van-transfer-from-port-barton-to-puerto-princesa',
        origin: 'PBT',
        destination: 'PPS',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Port Barton Terminal', 'Hotel near Port Barton Village'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            markdown_content: {
                price: 150,
                duration: '1 day',
                itinerary: {
                    '7:00 AM': 'Meet your driver at Port Barton Terminal. Your journey starts with a brief orientation, ensuring all your needs are addressed before departure.',
                    '7:30 AM': 'Depart from Port Barton. As you leave, take in the last views of this quaint village. The trip will take you through scenic rural landscapes and coastal views of Palawan.',
                    '10:30 AM': 'Rest Stop. We schedule a brief stop midway for refreshments and a comfort break, ensuring a pleasant journey throughout.',
                    '1:00 PM': 'Arrive in Puerto Princesa. Your driver takes you through the city, providing a glimpse of local life before reaching your destination.',
                    '1:30 PM': 'Hotel Drop-off within City Proper. You arrive at your hotel, bidding farewell to your driver. End of service.',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional and courteous driver', 'Fuel costs', 'Passenger insurance', 'Comfort stop refreshments'],
                exclusions: ['Meals', 'Personal expenses', 'Tips and gratuities'],
                importantNotes: [
                    'This service is available for direct hotel pick-up and drop-off within the City Proper area only.',
                    'Make sure to confirm your pick-up time and location 24 hours before departure.',
                    'Each passenger is allowed one standard luggage and a small carry-on. Please inform us in advance if you are traveling with oversized luggage.',
                    'Children must be accompanied by an adult.',
                    'In case of extreme weather conditions or unforeseen traffic delays, travel times may vary.',
                ],
                bookingPolicy: 'Full payment is required to secure your booking. Cancellations made at least 48 hours before the scheduled departure time will be fully refunded minus a handling fee. No refunds for cancellations made within 48 hours of departure time.',
            },
            seo_description: 'Book your Private Van Transfer from Port Barton to Puerto Princesa. Travel comfortably and conveniently with our dependable service.',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 150,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 45,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:36:30.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPS-SVC',
        title: 'Private Van Transfer from Puerto Princesa to San Vicente',
        slug: 'private-van-transfer-from-puerto-princesa-to-san-vicente',
        origin: 'PPS',
        destination: 'SVC',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            dropoff: ['Hotel within San Vicente Town Center', 'Hotel in Alimanguan'],
            markdown_content: {
                price: 150,
                duration: '1 day',
                itinerary: {
                    '7:00 AM': 'Pickup at Hotel within Puerto Princesa City Proper.',
                    '7:15 AM': 'Departure from Puerto Princesa, beginning the scenic drive to San Vicente.',
                    '10:00 AM': 'Short break at a local restaurant or rest stop for refreshments and restroom (personal expenses not included).',
                    '12:30 PM': 'Estimated arrival in San Vicente. Proceed to drive through the serene landscapes and towns.',
                    '1:00 PM': 'Drop off at Hotel within San Vicente Town Center.',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional driver', 'Fuel surcharge', 'Hotel pickup and drop-off services'],
                exclusions: ['Meals and drinks', 'Gratuities (optional)', 'Personal expenses during rest stops'],
                importantNotes: [
                    'Please be ready at your pickup location at least 15 minutes before your scheduled pickup time.',
                    'Children must be accompanied by an adult.',
                    'This transfer is subject to weather and road conditions; any changes or cancellations will be communicated as early as possible.',
                    'The exact duration of the journey may vary depending on traffic and weather conditions.',
                ],
                bookingPolicy:
                    'Full payment is required at the time of booking to secure your transfer. Cancellations made more than 24 hours before the scheduled transfer time will be refunded in full, minus any processing fees. Cancellations made less than 24 hours before the departure time are non-refundable.',
            },
            seo_description: 'Book your Private Van Transfer from Puerto Princesa to San Vicente. Enjoy a smooth and convenient ride with our reliable service.',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 150,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 34,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:36:56.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPUR-PPS-PBT',
        title: 'Private Underground River Tour from Puerto Princesa to Port Barton',
        slug: 'private-underground-river-tour-from-puerto-princesa-to-port-barton',
        origin: 'PPS',
        destination: 'PBT',
        content: {
            sched: ['6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM'],
            duration: '8h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            dropoff: ['Port Barton Terminal', 'Hotel near Port Barton Village'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '6:00 AM': 'Hotel pickup within Puerto Princesa City Proper',
                    '8:30 AM': 'Arrive at Sabang Wharf, prepare for the Underground River tour',
                    '9:00 AM': 'Start of the Underground River Tour. A guided paddle boat will take you inside the cave to witness its stunning rock formations and resident wildlife.',
                    '11:00 AM': 'End of Underground River tour. Proceed to Sabang Beach for a short rest.',
                    '12:00 PM': 'Lunch at a local restaurant in Sabang area.',
                    '1:30 PM': 'Depart Sabang for Port Barton.',
                    '4:00 PM': 'Arrive in Port Barton. Short tour around the area, including White Beach and a tranquil boat ride to see nearby islands.',
                    '6:00 PM': 'Drop-off at Port Barton Terminal.',
                },
                inclusions: ['All entrance fees', 'Private transportation with air-conditioning', 'English-speaking tour guide', 'Underground River tour with boat fee', 'Environmental fee', 'Lunch', 'Short Port Barton area tour'],
                exclusions: ['Dinner', 'Personal expenses', 'Gratuities (optional)', 'Accommodation in Port Barton'],
                importantNotes: [
                    'Guests are advised to bring comfortable walking shoes, light clothing, sunscreen, and a waterproof bag.',
                    'Children must be accompanied by an adult.',
                    'The Underground River tour is subject to weather conditions and permits; an alternative destination may be provided if necessary.',
                    'Please be ready at least 15 minutes before your pickup time.',
                    'Passengers are required to obtain a permit for the Underground River tour in advance; assistance for this can be provided upon booking.',
                ],
                bookingPolicy: 'A 20% non-refundable deposit is required upon booking to secure your reservation. The balance will be payable 48 hours before the tour date. Cancellations or changes made less than 24 hours before the tour date are subject to 100% cancellation fee.',
            },
            seo_description: 'Book your Private Underground River Tour from Puerto Princesa to Port Barton. Enjoy a seamless and unforgettable adventure with our convenient service.',
        },
        listing_type: 'PRIVATE_TRANSFER_WITH_TOUR',
        ratePlan: {
            base: 240,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 31,
        images: ['UR', 'VAN'],
        updatedAt: '2024-08-05T16:39:25.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-SAB-SVC',
        title: 'Private Van Transfer from Sabang to San Vicente',
        slug: 'private-van-transfer-from-sabang-to-san-vicente',
        origin: 'SAB',
        destination: 'SVC',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            dropoff: ['Hotel within San Vicente Town Center', 'Hotel in Alimanguan'],
            markdown_content: {
                price: 180,
                duration: '1 day',
                itinerary: {
                    '7:00 AM': 'Your day begins with a prompt pickup from your hotel near Sabang Beach. Settle into our comfortable, air-conditioned van as we prepare for departure.',
                    '7:30 AM': 'Depart from Sabang, embarking on a scenic drive towards San Vicente. Along the way, enjoy the lush landscapes and serene beauty of the countryside.',
                    '10:30 AM': 'Short break at a local rest stop. A perfect opportunity to stretch your legs, enjoy a quick snack, or simply take in the views.',
                    '1:00 PM': 'Arrival at San Vicente. Our driver navigates through the charming streets of this idyllic town to ensure a smooth passage to your destination.',
                    '1:30 PM': 'End of the journey as we reach your hotel within San Vicente Town Center. Our driver will assist with your luggage, ensuring a smooth transition to your next adventure.',
                },
                inclusions: ['Air-conditioned private van transfer', 'Professional and friendly driver', 'Fuel surcharge', 'Hotel pickup and drop-off service'],
                exclusions: ['Meals and beverages', 'Personal expenses', 'Tips and gratuities (optional)'],
                importantNotes: [
                    'Please be ready at your pickup point at least 15 minutes before departure time.',
                    'Carry essential documents such as ID for verification purposes.',
                    'Keep your belongings secure and notify the driver of any fragile items.',
                    'Wearing a seatbelt is mandatory during the trip.',
                ],
                bookingPolicy:
                    'To secure your booking, full payment is required at least 24 hours before the transfer date. Cancellations made 48 hours before the scheduled departure time will be fully refunded, minus a processing fee. No refunds will be issued for cancellations made within 24 hours of the transfer date.',
            },
            seo_description: 'Book your Private Van Transfer from Sabang to San Vicente. Enjoy a comfortable and convenient ride. Book your hassle-free transfer today!',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 180,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 27,
        images: ['VAN'],
        updatedAt: '2024-08-05T12:32:45.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-SAB-PBT',
        title: 'Private Van Transfer from Sabang to Port Barton',
        slug: 'private-van-transfer-from-sabang-to-port-barton',
        origin: 'SAB',
        destination: 'PBT',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            dropoff: ['Port Barton Terminal', 'Hotel near Port Barton Village'],
            markdown_content: {
                price: 180,
                duration: 'One day',
                itinerary: {
                    '7:00 AM': 'Pickup from your hotel near Sabang Beach',
                    '7:15 AM': 'Depart for Port Barton, embark on a scenic journey',
                    '10:15 AM': 'Rest stop and refreshments (at own expense)',
                    '12:00 PM': 'Continue the journey towards Port Barton',
                    '2:00 PM': 'Arrive at Port Barton Terminal, end of service',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional and friendly driver', 'Fuel and toll fees', 'Hotel pickup near Sabang Beach'],
                exclusions: ['Meals and refreshments', 'Personal expenses', 'Tips and gratuities (optional)'],
                importantNotes: [
                    'Please be ready at least 15 minutes before your scheduled pickup time.',
                    'The journey duration is subject to traffic and weather conditions; the itinerary is approximate and may vary.',
                    'Make sure to bring any necessary personal items for the journey, such as water, snacks, sunscreen, etc.',
                    'Children must be accompanied by an adult.',
                ],
                bookingPolicy: 'Full payment is required at the time of booking. Free cancellation up to 24 hours before the service begins. Cancellations made less than 24 hours before the service time will not be refunded.',
            },
            seo_description: 'Book your Private Van Transfer from Sabang to Port Barton. Travel comfortably and enjoy scenic views along the way. Book your private transfer now!',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 180,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 44,
        images: ['VAN'],
        updatedAt: '2024-08-05T12:48:34.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-ELN-SAB',
        title: 'Private Van Transfer from El Nido to Sabang',
        slug: 'private-van-transfer-from-el-nido-to-sabang',
        origin: 'ELN',
        destination: 'SAB',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            dropoff: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            markdown_content: {
                price: 190,
                duration: 'One day',
                itinerary: {
                    '7:00 AM': 'Meet your private driver at the El Nido Bus Terminal for a prompt departure.',
                    '7:15 AM': "Depart from El Nido, beginning your journey towards Sabang. Enjoy the scenic drive through Palawan's stunning countryside.",
                    '12:00 PM': 'Take a break for lunch at one of the local restaurants along the way (meal not included in the package).',
                    '2:00 PM': "Resume travel towards Sabang, with opportunities for brief photo stops, admiring Palawan's lush landscapes.",
                    '4:00 PM': 'Estimated time of arrival at your hotel near Sabang Beach. Check-in and enjoy the rest of the day at your leisure.',
                },
                inclusions: ['Private air-conditioned van transport', 'Professional driver', 'Fuel charges', 'Hotel pickup and drop-off', 'Personalized assistance'],
                exclusions: ['Meals and beverages', 'Personal expenses', 'Tips and gratuities (optional)', 'Any additional costs not mentioned in the inclusions'],
                importantNotes: [
                    'Confirmation will be received at the time of booking.',
                    'Please be ready at the pickup point 15 minutes before the scheduled time.',
                    'This is a private transfer, only your group will participate.',
                    'The duration of transfers is approximate; the exact duration will depend on the time of day and traffic conditions.',
                    'A maximum of X passengers per booking is allowed (depending on the vehicle size, ensure to specify the vehicle capacity).',
                    'Children must be accompanied by an adult.',
                ],
                bookingPolicy: 'Free cancellation up to 24 hours before the service starts. Cancellations within 24 hours of the service date will not be refundable.',
            },
            seo_description: 'Book your Private Van Transfer from El Nido to Sabang. Enjoy a comfortable journey through scenic routes. Book your hassle-free transfer today!',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 190,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 38,
        images: ['VAN'],
        updatedAt: '2024-08-05T12:57:19.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'SV-PPS-PBT',
        title: 'Shared Minivan Transfer from Puerto Princesa to Port Barton',
        slug: 'shared-minivan-transfer-from-puerto-princesa-to-port-barton',
        origin: 'PPS',
        destination: 'PBT',
        content: {
            sched: ['6:00 AM', '8:00 AM', '10:00 AM', '1:00 PM', '1:30 PM', '3:00 PM'],
            duration: '5h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            dropoff: ['Port Barton Terminal'],
            markdown_content: {
                price: 15,
                duration: '4 hours',
                itinerary: {
                    '6:00 AM': 'Pick up from your hotel within Puerto Princesa City Proper.',
                    '6:15 AM': 'Departure from Puerto Princesa. Start your journey towards Port Barton, enjoying a scenic ride.',
                    '8:30 AM': 'Short comfort break. Stretch your legs, use restrooms, or grab a snack.',
                    '10:00 AM': 'Arrival at Port Barton Terminal. End of service.',
                },
                inclusions: ['Air-conditioned minivan transfer', 'Professional driver', 'Gasoline and road tolls', 'Pick up from hotels within Puerto Princesa City Proper'],
                exclusions: ['Meals and beverages', 'Tips and gratuities (optional)', 'Personal expenses'],
                importantNotes: [
                    'Please be ready at your hotel lobby at least 15 minutes before the scheduled pick-up time.',
                    'Luggage allowance is one suitcase and one carry-on bag per person. Please inform us in advance if you have oversized or excessive luggage.',
                    'Travel time may vary depending on traffic and weather conditions.',
                    'As this is a shared transfer, please be considerate of other passengers.',
                    "Wearing a face mask is recommended for travellers' safety.",
                ],
                bookingPolicy: 'Full payment is required at the time of booking. Free cancellation up to 24 hours before the service starts. No refunds will be issued for no-shows or cancellations made within 24 hours of the scheduled pick-up time.',
            },
            seo_description: 'Book your Shared Minivan Transfer from Puerto Princesa to Port Barton. Enjoy a convenient, comfortable, and affordable journey with our reliable service.',
        },
        listing_type: 'SHARED_TRANSFER',
        ratePlan: {
            base: 15,
            extra: 15,
            inc: 1,
        },
        provider_id: 'Lexxus',
        reviewCount: 50,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:40:59.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'Lexxus',
            name: 'Lexxus Shuttle',
            email: null,
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'SV-ELN-PPS',
        title: 'Shared Minivan Transfer from El Nido to Puerto Princesa',
        slug: 'shared-minivan-transfer-from-el-nido-to-puerto-princesa',
        origin: 'ELN',
        destination: 'PPS',
        content: {
            sched: ['5:00 AM', '6:00 AM', '8:00 AM', '10:00 AM', '12:00 PM', '1:00 PM', '5:00 PM', '6:00 PM'],
            duration: '5h',
            pickup: ['El Nido Bus Terminal'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            markdown_content: {
                price: 15,
                duration: 'One day',
                itinerary: {
                    '05:00 AM': 'Meet at El Nido Bus Terminal for departure.',
                    '05:15 AM': 'Departure from El Nido Bus Terminal. The journey begins towards Puerto Princesa, allowing you to enjoy scenic views along the way.',
                    '08:30 AM': 'Short break at a local restaurant for breakfast and refreshments (own expense).',
                    '12:00 PM': 'Another rest stop for lunch at a roadside diner, offering local cuisine (own expense).',
                    '03:00 PM': 'Arrival in Puerto Princesa. Dropoff at Hotel within City Proper.',
                },
                inclusions: ['Air-conditioned minivan transfer', 'Professional and courteous driver', 'Fuel surcharge'],
                exclusions: ['Meals and beverages', 'Personal expenses', 'Tips and gratuities (optional)'],
                importantNotes: [
                    'Please be at the pick-up point (El Nido Bus Terminal) at least 15 minutes before departure.',
                    'Exact drop-off location must be a hotel located within the city proper of Puerto Princesa.',
                    'Carry light jackets as it may get cold in the minivan.',
                    'Luggage allowance: 1 suitcase and 1 hand carry bag per person. Please inform us in advance if carrying oversized luggage.',
                ],
                bookingPolicy: 'Reservation required at least 48 hours in advance. Full payment required upon booking. Cancellations made at least 24 hours before the scheduled departure time will receive a full refund. No-shows and late cancellations will be charged in full.',
            },
            seo_description: 'Book your Shared Minivan Transfer from El Nido to Puerto Princesa. Enjoy a convenient and comfortable journey with our reliable transportation service.',
        },
        listing_type: 'SHARED_TRANSFER',
        ratePlan: {
            base: 14,
            extra: 14,
            inc: 1,
        },
        provider_id: 'Zion',
        reviewCount: 21,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:42:18.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'Zion',
            name: 'Zion Transport',
            email: null,
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'JT-ELN-A',
        title: 'Joining Island Hopping Tour A in El Nido',
        slug: 'joining-island-hopping-tour-a-in-el-nido',
        origin: 'ELN',
        destination: 'ELN',
        content: {
            sched: ['9:00 AM'],
            duration: '8h',
            pickup: ['El Nido Beach Boat Dock', 'El Nido Town Center', 'Hotels in Corong-corong Area'],
            dropoff: ['El Nido Beach Boat Dock'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '8:30am -9:00am': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Island Hopping Tour A- visits 5 Islands',
                    '5:00 PM': 'Drop off at El Nido Boat Dock',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)', 'Entrance to Big Lagoon', 'Visits to 7 Commando Beach, Shimizu Island, Secret Lagoon, and Payong-payong Beach'],
                exclusions: ['El Nido EcoTourism Development Fee(PHP 400 per person)', 'Dinner', 'Hotel Drop Off(You can ride tricycle going to your hotel)', 'Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability, weather conditions and price adjustments.',
            },
            seo_description: 'Book your Joining Island Hopping Tour A in El Nido. Explore stunning islands and lagoons with ease and convenience.',
        },
        listing_type: 'JOINING_TOUR',
        ratePlan: {
            base: 25.99,
            extra: 25.99,
            inc: 1,
        },
        provider_id: 'DSPal',
        reviewCount: 9,
        images: ['ELN-A'],
        updatedAt: '2024-08-05T16:42:49.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'JT-ELN-C',
        title: 'Joining Island Hopping Tour C in El Nido',
        slug: 'joining-island-hopping-tour-c-in-el-nido',
        origin: 'ELN',
        destination: 'ELN',
        content: {
            sched: ['9:00 AM'],
            duration: '8h',
            pickup: ['El Nido Beach Boat Dock', 'El Nido Town Center', 'Hotels in Corong-corong Area'],
            dropoff: ['El Nido Beach Boat Dock'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '8:30am -9:00am': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Island Hopping Tour C - visits 5 Islands',
                    '5:00 PM': 'Drop off at El Nido Boat Dock',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)', '5 Island Destination'],
                exclusions: ['El Nido EcoTourism Development Fee(PHP 400 per person)', 'Dinner', 'Hotel Drop Off(You can ride tricycle going to your hotel)', 'Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: 'Book your Joining Island Hopping Tour C in El Nido. Discover hidden beaches and vibrant coral reefs effortlessly.',
        },
        listing_type: 'JOINING_TOUR',
        ratePlan: {
            base: 24.99,
            extra: 24.99,
            inc: 1,
        },
        provider_id: 'DSPal',
        reviewCount: 13,
        images: ['ELN-C'],
        updatedAt: '2024-08-05T16:43:12.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PrivBoat-ELN-A',
        title: 'Private Boat Island Hopping Tour A in El Nido',
        slug: 'private-boat-island-hopping-tour-a-in-el-nido',
        origin: 'ELN',
        destination: 'ELN',
        content: {
            sched: ['8:00 AM', '8:30 AM', '9:00 AM'],
            duration: '8h',
            pickup: ['El Nido Beach Boat Dock', 'El Nido Town Center', 'Hotels in Corong-corong Area'],
            dropoff: ['El Nido Beach Boat Dock'],
            markdown_content: {
                duration: '1 day',
                itinerary: {
                    '8:30am -9:00am': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Island Hopping Tour A - visits 5 Islands',
                    '5:00 PM': 'Drop off at El Nido Boat Dock',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)', 'Entrance to Big Lagoon', 'Visits to 7 Commando Beach, Shimizu Island, Secret Lagoon, and Payong-payong Beach'],
                exclusions: ['El Nido EcoTourism Development Fee(PHP 400 per person)', 'Dinner', 'Hotel Drop Off(You can ride tricycle going to your hotel)', 'Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: 'Book your Private Boat Island Hopping Tour A in El Nido. Experience personalized exploration of breathtaking islands and lagoons.',
        },
        listing_type: 'PRIVATE_BOAT_TOUR',
        ratePlan: {
            base: 234,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 32,
        images: ['ELN-A'],
        updatedAt: '2024-08-05T16:43:39.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PBT-SAB',
        title: 'Private Van Transfer from Port Barton to Sabang',
        slug: 'private-van-transfer-from-port-barton-to-sabang',
        origin: 'PBT',
        destination: 'SAB',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Port Barton Terminal', 'Hotel near Port Barton Village'],
            dropoff: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            markdown_content: {
                price: 180,
                duration: '1 day',
                itinerary: {
                    '07:00 AM': 'Pickup at Port Barton Terminal',
                    '07:30 AM': 'Depart from Port Barton',
                    '10:30 AM': 'Short break at a local restaurant/coffee shop (meal not included)',
                    '01:00 PM': 'Resume journey to Sabang',
                    '03:00 PM': 'Arrival at Hotel near Sabang Beach',
                },
                inclusions: ['Private air-conditioned van', 'Professional driver', 'Fuel', 'Parking fees'],
                exclusions: ['Meals', 'Personal expenses', 'Tips (optional)'],
                importantNotes: [
                    'Please be ready at the pickup point 15 minutes before scheduled departure.',
                    'Travel time may vary depending on traffic and weather conditions.',
                    'Be sure to bring essentials for a comfortable ride: water, snacks, and entertainment.',
                    'Contact information for the driver will be provided 24 hours before departure.',
                ],
                bookingPolicy:
                    'Full payment is required to secure your booking. Cancellations made within 48 hours before the departure time are non-refundable. For cancellations made more than 48 hours before departure, a full refund will be issued. Modifications to the booking are subject to availability and may incur additional charges.',
            },
            seo_description: 'Book your Private Van Transfer from Port Barton to Sabang. Travel conveniently and comfortably with our reliable transportation service.',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 180,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 37,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:44:38.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-ELN-SVC',
        title: 'Private Van Transfer from El Nido to San Vicente',
        slug: 'private-van-transfer-from-el-nido-to-san-vicente',
        origin: 'ELN',
        destination: 'SVC',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            dropoff: ['Hotel within San Vicente Town Center', 'Hotel in Alimanguan'],
            markdown_content: {
                price: 130,
                duration: 'One Day',
                itinerary: {
                    '7:00 AM': 'Meet at El Nido Bus Terminal for departure.',
                    '7:30 AM': 'Departure from El Nido, beginning the scenic journey to San Vicente.',
                    '10:30 AM - 11:00 AM': 'Short rest stop along the way for refreshments and restroom break.',
                    '1:00 PM': 'Arrival at San Vicente Town Center, followed by drop-off at your hotel within the town center.',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional and courteous driver', 'Fuel surcharge', 'Hotel drop-off within San Vicente Town Center'],
                exclusions: ['Food and drinks', 'Gratuities (optional)', 'Personal expenses'],
                importantNotes: [
                    'Please be ready at the El Nido Bus Terminal by 6:45 AM for a prompt departure at 7:00 AM.',
                    'The exact duration of the transfer may vary depending on the time of day and traffic conditions.',
                    'Make sure to inform us in advance if you have special requirements or a significant amount of luggage.',
                    'Children must be accompanied by an adult.',
                    'This transfer is subject to favorable weather conditions; in case of cancellations due to severe weather, you will be given the option of an alternative date or a full refund.',
                ],
                bookingPolicy: 'Full payment is required at the time of booking. Cancellations made at least 24 hours before the scheduled departure time will receive a full refund, minus any processing fees. No refunds will be issued for cancellations made within 24 hours of the departure time.',
            },
            seo_description: 'Book your Private Van Transfer from El Nido to San Vicente. Experience a smooth and convenient journey with our reliable transportation service.',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 130,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 7,
        images: ['UR'],
        updatedAt: '2024-08-05T16:45:48.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-SVC-PPS',
        title: 'Private Van Transfer from San Vicente to Puerto Princesa',
        slug: 'private-van-transfer-from-san-vicente-to-puerto-princesa',
        origin: 'SVC',
        destination: 'PPS',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel within San Vicente Town Center', 'Hotel in Alimanguan'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            markdown_content: {
                price: 150,
                duration: 'One Day',
                itinerary: {
                    '07:00 AM': 'Pickup from your hotel in San Vicente Town Center.',
                    '07:15 AM': "Depart from San Vicente. Begin your journey towards Puerto Princesa. On the way, soak in the picturesque views of Palawan's countryside.",
                    '12:00 PM': 'Stop for lunch at a local restaurant (meal cost not included in the package).',
                    '12:45 PM': 'Continue the journey to Puerto Princesa. Enjoy a smooth ride as you pass through quaint villages and lush landscapes.',
                    '03:00 PM': 'Arrival in Puerto Princesa. Depending on traffic conditions, take a short break if necessary.',
                    '04:00 PM': 'Drop-off at your hotel within Puerto Princesa City Proper. End of service.',
                },
                inclusions: ['Private air-conditioned van transfer', 'Professional and courteous driver', 'Fuel surcharge', 'Pickup and drop-off at specified locations'],
                exclusions: ['Meals', 'Personal expenses', 'Tips and gratuities (optional)'],
                importantNotes: [
                    'This transfer is a private service; you will not share the van with other travelers.',
                    'The estimated duration of the trip is subject to change due to traffic conditions and any other unforeseen circumstances.',
                    'Pickup and drop-off points must be within the areas specified (San Vicente Town Center and Puerto Princesa City Proper). For different locations, additional charges may apply.',
                    'Passengers are advised to be ready for pickup at least 15 minutes before the scheduled departure.',
                ],
                bookingPolicy:
                    'To secure your booking, a full payment is required at the time of reservation. Cancellations made more than 24 hours before the scheduled transfer will receive a full refund. Cancellations made less than 24 hours before the transfer time will incur a 100% cancellation fee. In case of no-show, no refund will be given.',
            },
            seo_description: 'Book your Private Van Transfer from San Vicente to Puerto Princesa. Enjoy a comfortable and convenient ride with our reliable service.',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 150,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 29,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:39:51.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPUR-PPS-SVC',
        title: 'Private Underground River Tour from Puerto Princesa to San Vicente',
        slug: 'private-underground-river-tour-from-puerto-princesa-to-san-vicente',
        origin: 'PPS',
        destination: 'SVC',
        content: {
            sched: ['6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM'],
            duration: '8h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            dropoff: ['Hotel within San Vicente Town Center', 'Hotel in Alimanguan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '6:00 AM': 'Pickup from your hotel within Puerto Princesa City Proper.',
                    '8:30 AM': 'Arrive at Sabang Wharf, short break and prepare for the boat transfer.',
                    '9:00 AM': 'Boat transfer to the entrance of the Underground River.',
                    '9:30 AM': 'Start of the Underground River Tour. Marvel at the stunning limestone formations and experience the serene beauty as you paddle through the cave.',
                    '11:00 AM': 'End of the Underground River Tour. Proceed to a local restaurant for a sumptuous lunch.',
                    '12:30 PM': 'Depart from the restaurant and begin the journey to San Vicente.',
                    '3:30 PM': 'Stopover at a scenic spot for a brief relaxation and photo opportunity.',
                    '5:00 PM': 'Arrive at Hotel within San Vicente Town Center, drop-off and end of tour.',
                },
                inclusions: ['Hotel pickup and drop-off', 'Transportation by air-conditioned vehicle', 'Professional guide', 'All entrance fees', 'Boat transfers', 'Lunch', 'Environmental fee'],
                exclusions: ['Gratuities (optional)', 'Personal expenses', 'Snacks and additional beverages'],
                importantNotes: [
                    'Guests must carry valid ID for verification.',
                    'Expect some waiting time for the underground river tour due to its popularity.',
                    'Wear comfortable clothes and shoes; bring hats, sunglasses, and sunscreen for protection.',
                    'Participants must be physically fit as this tour involves some walking.',
                    'In case of inclement weather or unforeseen operational issues, alternative routes or adjustments to the itinerary may occur.',
                ],
                bookingPolicy: 'Free cancellation up to 48 hours before the scheduled pickup time. Within 48 hours of the tour, cancellations are subject to a 50% fee. No-shows are non-refundable.',
            },
            seo_description: "Book your Private Underground River Tour from Puerto Princesa to San Vicente. Explore nature's wonders with ease and comfort.",
        },
        listing_type: 'PRIVATE_TRANSFER_WITH_TOUR',
        ratePlan: {
            base: 270,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 4,
        images: ['UR', 'VAN'],
        updatedAt: '2024-08-05T16:46:16.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'SV-PBT-ELN',
        title: 'Shared Minivan Transfer from Port Barton to El Nido',
        slug: 'shared-minivan-transfer-from-port-barton-to-el-nido',
        origin: 'PBT',
        destination: 'ELN',
        content: {
            sched: ['8:00 AM', '1:00 PM'],
            duration: '3h30m',
            pickup: ['Port Barton Terminal'],
            dropoff: ['El Nido Bus Terminal'],
            markdown_content: {
                price: 15,
                duration: '4 hours',
                itinerary: {
                    '8:00 AM': 'Meet up at Port Barton Terminal. Please be ready at least 15 minutes before departure.',
                    '8:30 AM': 'Departure from Port Barton Terminal. Begin your journey towards El Nido with our experienced driver navigating through the scenic routes.',
                    '12:00 PM': 'Arrival at El Nido Bus Terminal. End of service.',
                },
                inclusions: ['Shared minivan ride from Port Barton to El Nido', 'Professional and friendly driver', 'Air-conditioned vehicle', 'Fuel surcharge'],
                exclusions: ['Meals and drinks', 'Personal expenses', 'Tips and gratuities', 'Pickup and drop-off from locations other than specified'],
                importantNotes: [
                    'Be at the meeting point 15 minutes before the scheduled departure time.',
                    'Only one piece of luggage per passenger is allowed due to limited space in the vehicle.',
                    "The travel itinerary may change depending on traffic conditions or road closures. Please be flexible and follow the driver's instructions.",
                    'Wear comfortable clothing and consider bringing snacks and water for the journey.',
                ],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made 24 hours before the departure time are eligible for a full refund. No-shows and late cancellations are non-refundable. For changes to your booking, please contact us at least 24 hours before the scheduled departure.',
            },
            seo_description: 'Book your Shared Minivan Transfer from Port Barton to El Nido. Travel comfortably and affordably with our convenient and reliable service.',
        },
        listing_type: 'SHARED_TRANSFER',
        ratePlan: {
            base: 15,
            extra: 15,
            inc: 1,
        },
        provider_id: 'Lexxus',
        reviewCount: 31,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:40:24.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'Lexxus',
            name: 'Lexxus Shuttle',
            email: null,
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PBT-ELN',
        title: 'Private Van Transfer from Port Barton to El Nido',
        slug: 'private-van-transfer-from-port-barton-to-el-nido',
        origin: 'PBT',
        destination: 'ELN',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Port Barton Terminal', 'Hotel near Port Barton Village'],
            dropoff: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            markdown_content: {
                price: 130,
                duration: 'One day',
                itinerary: {
                    '07:00 AM': 'Meet at Port Barton Terminal. After a brief meet and greet with our professional driver, your private van transfer to El Nido will commence.',
                    '07:30 AM': 'Depart from Port Barton. Settle in and enjoy the ride as you pass by the beautiful landscapes of Palawan.',
                    '10:00 AM': 'Short Break. Take a moment to stretch your legs, use restrooms, or grab a quick snack at a local stop.',
                    '12:30 PM': 'Arrive at El Nido. Conclude your journey at El Nido Bus Terminal, ready to explore the stunning landscapes and crystal-clear waters of El Nido.',
                },
                inclusions: ['Private air-conditioned van', 'Professional driver', 'Fuel costs', 'Parking fees'],
                exclusions: ['Meals and Drinks', 'Personal expenses', 'Tips (optional)'],
                importantNotes: [
                    'Please be ready at the pickup point 15 minutes before departure time.',
                    'Each passenger is allowed one standard size suitcase and one small carry-on bag.',
                    'The transfer might take longer due to traffic conditions or weather.',
                    'This is a private transfer, only your group will participate.',
                ],
                bookingPolicy:
                    'To secure your booking, payment must be made in advance. Cancellations made 48 hours before the scheduled departure will receive a full refund. Any cancellations made within 48 hours of the scheduled departure will not be eligible for a refund. In case of no-show, the booking will be considered as used.',
            },
            seo_description: 'Book your Private Van Transfer from Port Barton to El Nido. Enjoy a comfortable and convenient journey with our reliable transportation service.',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 130,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 6,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:44:08.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-ELN-PPS',
        title: 'Private Van Transfer from El Nido to Puerto Princesa',
        slug: 'private-van-transfer-from-el-nido-to-puerto-princesa',
        origin: 'ELN',
        destination: 'PPS',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            markdown_content: {
                price: 150,
                duration: 'One day',
                itinerary: {
                    '07:00 AM': 'Meet your driver at El Nido Bus Terminal and start your journey towards Puerto Princesa.',
                    '10:30 AM': 'Short stopover at Roxas for a quick refreshment break (Food and drinks at personal expense).',
                    '01:00 PM': 'Lunch break at a local restaurant along the highway (Lunch at personal expense).',
                    '02:00 PM': 'Continue the journey towards Puerto Princesa, enjoying the lush landscapes of Palawan.',
                    '05:00 PM': 'Arrive at your designated hotel within the City Proper of Puerto Princesa. End of service.',
                },
                inclusions: ['Private air-conditioned van', 'Professional driver', 'Fuel costs', 'Hotel drop-off within City Proper'],
                exclusions: ['Meals and drinks', 'Personal expenses', 'Tips and gratuities'],
                importantNotes: [
                    'Pick-up is strictly at El Nido Bus Terminal.',
                    'Please be ready at least 15 minutes before your scheduled pick-up time.',
                    'Drop-off is limited to hotels within Puerto Princesa City Proper.',
                    'The journey duration is subject to road and traffic conditions.',
                    'Maximum of 10 passengers per van for comfort and convenience.',
                ],
                bookingPolicy: 'Full payment is required upon booking. Cancellations made 48 hours before the scheduled departure will be fully refunded, minus a processing fee. No refunds will be provided for cancellations made within 48 hours of the travel date or no-shows.',
            },
            seo_description: 'Book your Private Van Transfer from El Nido to Puerto Princesa. Enjoy a comfortable and hassle-free journey with our reliable transportation service.',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 150,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 7,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:45:14.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'JT-ELN-B',
        title: 'Joining Island Hopping Tour B in El Nido',
        slug: 'joining-island-hopping-tour-b-in-el-nido',
        origin: 'ELN',
        destination: 'ELN',
        content: {
            sched: ['9:00 AM'],
            duration: '8h',
            pickup: ['El Nido Beach Boat Dock', 'El Nido Town Center', 'Hotels in Corong-corong Area'],
            dropoff: ['El Nido Beach Boat Dock'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '8:30am -9:00am': 'Pickup at your hotel or meetup at location. ',
                    '9:00am - 4:00pm': 'Island Hopping Tour B - visits 5 Islands',
                    '5:00 PM': 'Drop off at El Nido Boat Dock',
                },
                inclusions: ['Lunch', 'Hotel Pickup(within Pickup Area)', 'Entrance to Big Lagoon', 'Visits to 7 Commando Beach, Shimizu Island, Secret Lagoon, and Payong-payong Beach'],
                exclusions: ['El Nido EcoTourism Development Fee(PHP 400 per person)', 'Dinner', 'Hotel Drop Off(You can ride tricycle going to your hotel)', 'Tips and gratuities', 'Snorkeling Gears and Aquashoes Rental'],
                importantNotes: ['Island it may change without notice. ', 'Please bring sunscreen and towels'],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: 'Book your Joining Island Hopping Tour B in El Nido. Discover stunning beaches and snorkeling spots effortlessly.',
        },
        listing_type: 'JOINING_TOUR',
        ratePlan: {
            base: 25.99,
            extra: 25.99,
            inc: 1,
        },
        provider_id: 'DSPal',
        reviewCount: 12,
        images: ['ELN-B'],
        updatedAt: '2024-08-05T16:46:54.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'SV-PBT-PPS',
        title: 'Shared Minivan Transfer from Port Barton to Puerto Princesa',
        slug: 'shared-minivan-transfer-from-port-barton-to-puerto-princesa',
        origin: 'PBT',
        destination: 'PPS',
        content: {
            sched: ['6:00 AM', '8:00 AM', '10:00 AM', '1:00 PM', '1:30 PM', '3:00 PM', '5:00 PM'],
            duration: '3h30m',
            pickup: ['Port Barton Terminal'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            markdown_content: {
                price: 15,
                duration: '1 day',
                itinerary: {
                    '6:00 AM': 'Meet at Port Barton Terminal for a short briefing and boarding. Please be punctual to ensure a timely departure.',
                    '6:15 AM': 'Depart from Port Barton. As you leave the quiet village, watch the scenery transition from rustic beaches to rural landscapes.',
                    '10:00 AM': 'Estimate time of arrival in Puerto Princesa\nDrop off at your hotel or at Puerto Princesa Airport',
                },
                inclusions: ['Air-conditioned shared minivan transport', 'Professional and experienced driver', 'Fuel charges', 'Hotel drop-off within Puerto Princesa City Proper'],
                exclusions: ['Meals and beverages', 'Personal expenses', 'Tips and gratuities (optional)'],
                importantNotes: [
                    'Exact pickup time may vary depending on the number of passengers and traffic conditions. Please be ready by 5:45 AM.',
                    'Remember to bring snacks and water for the journey, as stops are limited.',
                    'Ensure you have all your belongings before leaving the van.',
                    'Wearing a mask inside the van is recommended for the safety of all passengers.',
                ],
                bookingPolicy: 'Full payment is required at the time of booking. Cancellations made at least 24 hours before the scheduled departure time will receive a full refund. No refunds will be issued for no-shows or cancellations made within 24 hours of departure time.',
            },
            seo_description: 'Book your Shared Minivan Transfer from Port Barton to Puerto Princesa. Enjoy a convenient, comfortable, and affordable ride with our reliable service.',
        },
        listing_type: 'SHARED_TRANSFER',
        ratePlan: {
            base: 15,
            extra: 15,
            inc: 1,
        },
        provider_id: 'Lexxus',
        reviewCount: 23,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:41:46.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'Lexxus',
            name: 'Lexxus Shuttle',
            email: null,
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPS-PBT',
        title: 'Private Van Transfer from Puerto Princesa to Port Barton',
        slug: 'private-van-transfer-from-puerto-princesa-to-port-barton',
        origin: 'PPS',
        destination: 'PBT',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            dropoff: ['Port Barton Terminal', 'Hotel near Port Barton Village'],
            markdown_content: {
                price: 150,
                duration: '1 day',
                itinerary: {
                    '7:00 AM': 'Pick-up from your hotel within Puerto Princesa City Proper.',
                    '7:30 AM': 'Departure from Puerto Princesa. Start of journey towards Port Barton.',
                    '9:30 AM': 'Short stopover at a local restaurant for breakfast and refreshments (optional, at your own expense).',
                    '11:00 AM': 'Resume journey to Port Barton.',
                    '1:00 PM': 'Estimated arrival at Port Barton Terminal. End of transfer service.',
                },
                inclusions: ['Private van transfer', 'Professional driver', 'Fuel cost', 'Pick-up from your hotel within Puerto Princesa City Proper', 'Drop-off at Port Barton Terminal'],
                exclusions: ['Meals and drinks', 'Personal expenses', 'Tips and gratuities (optional)', 'Any additional stops or detours beyond the designated route'],
                importantNotes: [
                    'This is a private transfer, ensuring comfort and privacy.',
                    'Please be ready at least 15 minutes before your scheduled pick-up time.',
                    'Travel time may vary depending on traffic and weather conditions.',
                    'Ensure that your booking details are accurate to avoid any inconvenience.',
                    'Contact details of the driver will be provided a day before the transfer.',
                ],
                bookingPolicy:
                    'Booking must be made at least 48 hours in advance. Cancellations made 24 hours before the scheduled pick-up time are eligible for a full refund. No-shows and late cancellations will not be refunded. In case of unforeseen circumstances leading to the cancellation of the transfer by the service provider, a full refund will be processed.',
            },
            seo_description: 'Book your Private Van Transfer from Puerto Princesa to Port Barton. Enjoy a comfortable and convenient journey with our reliable transportation service.',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 130,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 4,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:47:32.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'JT-PPUR',
        title: 'Joining Puerto Princesa Underground River Tour',
        slug: 'joining-puerto-princesa-underground-river-tour',
        origin: 'PPS',
        destination: 'PPS',
        content: {
            sched: ['6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM'],
            duration: '8h',
            pickup: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Palawan'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km of City Proper', 'Astoria Palawan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '6:00 AM': 'Pickup from your hotel within Puerto Princesa City Proper',
                    '8:30 AM': "Arrive at the Puerto Princesa Subterranean River National Park. Enjoy a guided boat tour through the underground river's impressive caves and marvel at its natural beauty.",
                    '11:30 AM': 'Lunch at a local restaurant, featuring a selection of traditional Filipino dishes.',
                    '1:00 PM': 'Departure  back to your hotel in Puerto Princesa. Journey through the picturesque landscapes of Palawan, taking in the lush mountains and quaint towns along the way.',
                    '3:00 PM': 'Arrival at your hotel in Puerto Princesa. End of the Tour',
                },
                inclusions: [
                    'Hotel pickup and drop-off',
                    'Air-conditioned vehicle transfer from Puerto Princesa to El Nido',
                    'Entrance fees to the Puerto Princesa Subterranean River National Park',
                    'Boat ride and guided tour of the Underground River',
                    'Lunch at a local restaurant',
                    'Professional English-speaking guide',
                    'Environmental fee',
                ],
                exclusions: ['Breakfast and dinner', 'Personal expenses', 'Tips for guide and driver (optional)'],
                importantNotes: [
                    'A valid ID is required on the day of the tour.',
                    'Guests are advised to wear comfortable clothing and bring sunscreen, insect repellent, and a hat.',
                    'Children under 8 years old are not recommended to participate in the underground river tour.',
                    'Subject to favorable weather conditions. If canceled due to poor weather, you will be given the option of an alternative date or a full refund.',
                ],
                bookingPolicy: 'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date will be fully refunded, excluding a 10% processing fee. Cancellations made within 7 days of the tour date are non-refundable.',
            },
            seo_description: 'Book your Joining Puerto Princesa Underground River Tour. Experience this natural wonder effortlessly. Secure your unforgettable adventure now!',
        },
        listing_type: 'JOINING_TOUR',
        ratePlan: {
            base: 34.99,
            extra: 34.99,
            inc: 1,
        },
        provider_id: 'DSPal',
        reviewCount: 36,
        images: ['VAN', 'UR'],
        updatedAt: '2024-08-05T23:49:01.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPUR-ELN-PPS',
        title: 'Private Underground River Tour from El Nido to Puerto Princesa',
        slug: 'private-underground-river-tour-from-el-nido-to-puerto-princesa',
        origin: 'ELN',
        destination: 'PPS',
        content: {
            sched: ['4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM'],
            duration: '8h',
            pickup: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '4:00 AM': 'Meet at El Nido Bus Terminal. Depart for Puerto Princesa Subterranean River National Park.',
                    '8:00 AM': 'Brief stop for breakfast at a local restaurant (at own expense).',
                    '10:30 AM': 'Arrive at Puerto Princesa Subterranean River National Park. Register and get a safety briefing.',
                    '11:00 AM': 'Start of the guided boat tour of the Underground River, exploring its major formations and chambers.',
                    '1:00 PM': 'End of the river tour. Lunch at a local restaurant near the park.',
                    '2:00 PM': 'Free time for optional activities or relax at the Sabang Beach area.',
                    '3:00 PM': 'Depart from Puerto Princesa Subterranean River National Park back to El Nido.',
                    '7:00 PM': 'Arrival at the Hotel within City Proper. End of the tour.',
                },
                inclusions: ['Air-conditioned vehicle transportation from El Nido to Puerto Princesa and back', 'Private boat tour of the Underground River', 'Licensed tour guide', 'Environmental fee and entrance permits', 'Lunch at a local restaurant', 'Personalized assistance'],
                exclusions: ['Breakfast', 'Dinner', 'Personal expenses', 'Optional gratuities'],
                importantNotes: [
                    'Pregnant women and individuals with heart conditions or mobility issues should consult their physician before booking.',
                    'Children under 8 years of age are not recommended for this tour.',
                    'Bring comfortable walking shoes, insect repellent, sun protection, and a waterproof bag for electronics.',
                    'The tour schedule may vary due to weather conditions or local traffic.',
                    'Arrival time back to hotel is approximate and may vary depending on weather and traffic conditions.',
                ],
                bookingPolicy:
                    'To secure your reservation, full payment is required at the time of booking. Cancellations made more than 72 hours prior to the tour date will receive a full refund, minus any transaction fees. Cancellations made within 72 hours of the tour date are non-refundable. In the event of extreme weather or unforeseen circumstances forcing the cancellation of the tour, a full refund or alternative tour date will be offered.',
            },
            seo_description: 'Secure Online Booking for Private Underground River Tour: El Nido to Puerto Princesa. Plan your adventure hassle-free!',
        },
        listing_type: 'PRIVATE_TRANSFER_WITH_TOUR',
        ratePlan: {
            base: 240,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 22,
        images: ['UR', 'VAN'],
        updatedAt: '2024-08-05T16:48:44.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPUR-ELN-SAB',
        title: 'Private Underground River Tour from El Nido to Sabang',
        slug: 'private-underground-river-tour-from-el-nido-to-sabang',
        origin: 'ELN',
        destination: 'SAB',
        content: {
            sched: ['6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM'],
            duration: '8h',
            pickup: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            dropoff: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '6:00 AM': 'Meet at El Nido Bus Terminal for pick-up and start of the journey towards Sabang.',
                    '10:00 AM': 'Arrive in Sabang. Enjoy a light snack while preparing for the river tour.',
                    '10:30 AM': 'Begin the Private Underground River Tour. Board your boat and enter the cave, marveling at the stunning rock formations and crystal-clear waters.',
                    '12:30 PM': 'End of the river tour. Savor a delicious lunch at a local restaurant, offering a variety of local dishes.',
                    '1:30 PM': 'Free time to explore the Sabang area. Enjoy the beach or take a leisure walk in the nearby forest.',
                    '3:00 PM': 'Depart from Sabang towards the hotel near Sabang Beach.',
                    '4:00 PM': 'Arrive and check-in at the hotel. The rest of the day is at your leisure.',
                },
                inclusions: ['Private transportation from El Nido to Sabang and back', 'Private boat tour of the Underground River', 'Professional tour guide', 'All entrance and environmental fees', 'Lunch at a local restaurant', 'Light snack upon arrival in Sabang', 'Bottled water'],
                exclusions: ['Hotel accommodation', 'Personal expenses', 'Tips and gratuities', 'Breakfast and dinner'],
                importantNotes: [
                    'Hotel pick-up and drop-off are not included; guests are advised to meet at El Nido Bus Terminal.',
                    'The tour is weather-dependent, and schedules may change due to weather conditions.',
                    'Please wear comfortable clothing and footwear suitable for walking and water activities.',
                    'Guests are advised to secure their belongings in waterproof bags during the boat ride.',
                    'Minimum age for participants is 6 years old.',
                    'Pregnant women and individuals with serious medical conditions are advised to consult their doctors before joining the tour.',
                ],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made within 7 days of the tour date are non-refundable. In case of no-show, the full price of the tour will be charged. Changes to the booking are subject to availability and must be made at least 5 days prior to the tour date.',
            },
            seo_description: 'Effortless Online Booking for Private Underground River Tour: El Nido to Sabang. Reserve your adventure today!',
        },
        listing_type: 'PRIVATE_TRANSFER_WITH_TOUR',
        ratePlan: {
            base: 240,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 31,
        images: ['UR', 'VAN'],
        updatedAt: '2024-08-05T16:49:21.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-PPUR-PBT-PPS',
        title: 'Private Underground River Tour from Port Barton to Puerto Princesa',
        slug: 'private-underground-river-tour-from-port-barton-to-puerto-princesa',
        origin: 'PBT',
        destination: 'PPS',
        content: {
            sched: ['4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM'],
            duration: '8h',
            pickup: ['Port Barton Terminal', 'Hotel near Port Barton Village'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            markdown_content: {
                price: 270,
                duration: '1 day',
                itinerary: {
                    '4:00 AM': 'Pickup from Port Barton Terminal',
                    '4:00 AM - 7:00 AM': 'Travel to Puerto Princesa Subterranean River National Park',
                    '7:00 AM - 8:00 AM': 'Breakfast at a local restaurant (own expense)',
                    '8:00 AM - 8:30 AM': 'Registration and briefing at the park entrance',
                    '8:30 AM - 11:30 AM': 'Guided boat tour of the Underground River',
                    '11:30 AM - 12:30 PM': 'Free time to explore the park surroundings',
                    '12:30 PM - 1:30 PM': 'Lunch at a local restaurant',
                    '1:30 PM - 4:30 PM': 'Travel back to Puerto Princesa',
                    '4:30 PM - 5:00 PM': 'Quick city tour and souvenir shopping',
                    '5:00 PM': 'Drop off at Hotel within City Proper',
                },
                inclusions: [
                    'Private air-conditioned vehicle transfer from Port Barton to Puerto Princesa and back',
                    'Entrance fees to Puerto Princesa Subterranean River National Park',
                    'Boat permits and fees for the Underground River tour',
                    'Licensed tour guide',
                    'Environmental fee',
                    'Lunch at a local restaurant',
                    'Personal accident insurance',
                ],
                exclusions: ['Breakfast', 'Dinner', 'Personal expenses', 'Tips and gratuities'],
                importantNotes: [
                    'Participants must bring valid identification for park entry.',
                    'Pregnant women and individuals with back problems are advised to consult their doctors before joining the tour.',
                    'The tour involves moderate walking; comfortable and sturdy footwear is recommended.',
                    'In case of adverse weather conditions or government restrictions, parts of the tour may be modified or cancelled for safety reasons.',
                    'Guests are encouraged to bring water-resistant bags or pouches for electronic devices.',
                ],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made up to 7 days before the tour date are eligible for a full refund. Cancellations made within 7 days of the tour date will incur a 50% cancellation fee. No-shows and cancellations made within 24 hours of the tour date will not be refunded. Changes to the booking may be subject to availability and price adjustments.',
            },
            seo_description: 'Convenient Online Booking for Private Underground River Tour: Port Barton to Puerto Princesa. Book your adventure now!',
        },
        listing_type: 'PRIVATE_TRANSFER_WITH_TOUR',
        ratePlan: {
            base: 240,
            extra: 35,
            inc: 2,
        },
        provider_id: 'DSPal',
        reviewCount: 22,
        images: ['UR', 'VAN'],
        updatedAt: '2024-08-05T16:49:46.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-SVC-ELN',
        title: 'Private Van Transfer from San Vicente to El Nido',
        slug: 'private-van-transfer-from-san-vicente-to-el-nido',
        origin: 'SVC',
        destination: 'ELN',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel within San Vicente Town Center', 'Hotel in Alimanguan'],
            dropoff: ['El Nido Bus Terminal', 'Hotel in Corong Corong Area', 'Hotel within El Nido Town Center(+ USD 2.50)', 'Lio Area (+ USD 20.00)', 'Nacpan Area (+ USD 45.00)', 'Duli Area (+ USD 50.00)', 'Tenegueban and Sibaltan Area(+ USD 60.00)'],
            markdown_content: {
                price: 130,
                duration: 'One day',
                itinerary: {
                    '7:00 AM': 'Pickup from your hotel within San Vicente Town Center. Our representative will meet you at the lobby.',
                    '7:15 AM': 'Departure from San Vicente. As we embark on our journey, relax in the air-conditioned comfort of your private van. Feel free to ask the driver to stop for photo ops or quick breaks along the way.',
                    '11:30 AM': 'Mid-trip break. Enjoy a comfortable stop where you can stretch your legs, use restrooms, and grab snacks or lunch (at your own expense).',
                    '2:00 PM': 'Continue the journey towards El Nido, passing through scenic landscapes and quaint villages. The beauty of Palawan unfolds before your eyes.',
                    '4:00 PM': 'Arrival at El Nido Bus Terminal. Your adventure in El Nido begins here. Our driver will ensure your luggage is handled with care during the drop-off.',
                },
                inclusions: ['Private air-conditioned van', 'Professional and courteous driver', 'Fuel surcharge', 'Hotel pickup within San Vicente Town Center', 'Drop off at El Nido Bus Terminal'],
                exclusions: ['Meals and snacks', 'Personal expenses', 'Tips and gratuities (optional)'],
                importantNotes: [
                    'Please be ready at the hotel lobby at least 15 minutes before your scheduled pickup time.',
                    'This service is a private transfer, ensuring a more personalized and comfortable journey.',
                    'In case of heavy traffic or unavoidable delays, the itinerary timings may be adjusted accordingly.',
                    'Remember to bring snacks and water for the journey, as well as sun protection.',
                ],
                bookingPolicy:
                    'Full payment is required at the time of booking. Cancellations made at least 24 hours before the scheduled pickup time will receive a full refund. No-shows and cancellations made less than 24 hours before the scheduled time are non-refundable. Changes to the booking are allowed up to 12 hours before the scheduled pickup time, subject to availability.',
            },
            seo_description: 'Efficient Online Booking for Private Van Transfer: San Vicente to El Nido. Secure your hassle-free journey today!',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 130,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 19,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:50:16.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
    {
        code: 'PV-SAB-PPS',
        title: 'Private Van Transfer from Sabang to Puerto Princesa',
        slug: 'private-van-transfer-from-sabang-to-puerto-princesa',
        origin: 'SAB',
        destination: 'PPS',
        content: {
            sched: [],
            duration: '5h',
            pickup: ['Hotel near Sabang Beach', 'Atremaru Jungle Retreat'],
            dropoff: ['Hotel within City Proper', 'Hotel within 15km from City Proper', 'Puerto Princesa International Airport', 'Astoria Resort Palawan'],
            markdown_content: {
                price: 90,
                duration: 'One day',
                itinerary: {
                    '7:00 AM': 'Pickup from your hotel near Sabang Beach',
                    '7:15 AM': 'Departure towards Puerto Princesa',
                    'Mid-journey': 'Stop at a local restaurant for refreshments (cost not included)',
                    '11:30 AM': 'Drive through scenic rural and coastal landscapes, with brief photo stops upon request',
                    '1:00 PM': 'Lunch break at a local diner (cost not included)',
                    '2:00 PM': 'Resume journey to Puerto Princesa',
                    '4:00 PM': 'Arrival at Hotel within Puerto Princesa City Proper',
                },
                inclusions: ['Private air-conditioned van', 'Professional and friendly driver', 'Fuel fees', 'Parking fees', 'Hotel pickup and drop-off'],
                exclusions: ['Meals and refreshments', 'Personal expenses', 'Tips and gratuities (optional)'],
                importantNotes: [
                    'This service is a one-way transfer from Sabang to Puerto Princesa only.',
                    'Pickup is only available from hotels near Sabang Beach.',
                    'Please be ready at the hotel lobby at least 15 minutes before your scheduled pickup time.',
                    'Drop-off is limited to hotels within Puerto Princesa City Proper.',
                    'For any personal stops or detours during the trip, please communicate with the driver in advance. Additional charges may apply.',
                    'The exact duration of the transfer may vary depending on the traffic and weather conditions.',
                ],
                bookingPolicy: 'Booking should be made at least 24 hours before the scheduled transfer. Cancellations made 24 hours before the pickup time are free of charge. No-shows or late cancellations will be charged the full price.',
            },
            seo_description: 'Streamlined Online Booking for Private Van Transfer: Sabang to Puerto Princesa. Book your comfortable ride today!',
        },
        listing_type: 'PRIVATE_TRANSFER',
        ratePlan: {
            base: 99,
            extra: 0,
            inc: 10,
        },
        provider_id: 'DSPal',
        reviewCount: 36,
        images: ['VAN'],
        updatedAt: '2024-08-05T16:50:49.000Z',
        status: 'PUBLISHED',
        serviceProvider: {
            id: 'DSPal',
            name: 'Diskubre Palawan',
            email: 'admin@diskubrepalawan.com',
            mobile: null,
            notes: null,
        },
    },
];
