import { countries } from '~/data/countries';
import { imageResources } from '~/data/Images';
import { rates } from '~/data/rates';
export const ObjUpsert = (array: Record<string, any>[], matchKeys: Record<string, any>, updates: Record<string, any>): Record<string, any>[] => {
	let keyFound = false;

	const updatedArray = array.map((obj) => {
		const matches = Object.keys(matchKeys).every((key) => obj[key] === matchKeys[key]);
		if (matches) {
			keyFound = true;
			return { ...obj, ...updates };
		}
		return obj;
	});

	if (!keyFound) {
		updatedArray.push({ ...matchKeys, ...updates });
	}

	return updatedArray;
};

export const calculatePricing = (ratePlan: RatePlan) => {
	const rateTable = [];
	const { base, extra, inc } = ratePlan;
	for (let i = 1; i <= 10; i++) {
		const price = i <= inc ? base : base + (i - inc) * extra;
		rateTable.push({
			person: `${i} ${i > 1 ? 'persons' : 'person'}`,
			price: price,
		});
	}
	return rateTable;
};
export const getRandomSubset = <T>(count: number, arr: T[]): T[] => {
	if (count >= arr.length) {
		return [...arr];
	}

	const shuffledArray = arr.sort(() => 0.5 - Math.random());
	return shuffledArray.slice(0, count);
};

export const getUserCurrency = (countryCode: string) => {
	const countryInfo = countries.find((x) => x.countryCode === countryCode);
	return countryInfo?.currencyCode || 'USD';
};

export const shuffleArray = <T>(array: T[]): T[] => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

export const generateImages = (item) => {
	let images = imageResources.VAN;
	let thumbnail = null;
	const transfersType = ['PRIVATE_TRANSFER', 'SHARED_TRANSFER', 'PRIVATE_TRANSFER_WITH_TOUR', 'AIRPORT_TRANSFER'];
	if (transfersType.includes(item.listing_type)) {
		if (item.title.toLowerCase().includes('underground')) {
			images = images.concat(imageResources.UR);
		}
	} else {
		const key = Object.keys(imageResources).find((key) => item.code.includes(key));
		images = imageResources[key];
	}
	thumbnail = images[Math.floor(Math.random() * images.length)];
	images = shuffleArray(images);
	return { images, thumbnail };
};

export const currencyConverter = (amount: number, from: string, to: string) => {
	const itemMultiplier = rates[from];
	const currencyMultiplier = rates[to];
	return Math.round((amount / itemMultiplier) * currencyMultiplier);
};
