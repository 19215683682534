import { listings } from './../data/listings';
import { defineStore } from 'pinia';
import { generateImages, calculatePricing, getRandomSubset } from '~/utils/objectHelpers';

export const useListingStore = defineStore('listing', {
	state: () => ({
		listings: [],
		bestsellers: [],
		mobile: false,
		currateList: [
			{
				img: '/images/images/VAN/Van2.jpg',
				title: 'Airport Transfers',
			},
			{
				img: '/images/images/VAN/db747193eea37b3208f66dc63d32f1df.JPG',
				title: 'Shared Minivan Transfer',
			},
			{
				img: '/images/images/VAN/Van4.webp',
				title: 'Private Transfers',
			},
			{
				img: '/images/images/UR/UR5.jpg',
				title: 'Private Transfers with Underground River Tour',
			},
			{
				img: '/images/destinations/pps.jpg',
				title: 'Puerto Princesa Underground River Tour',
			},
			{
				img: '/images/images/HB/honda-bay-island-hopping.jpg',
				title: 'Tours in Puerto Princesa',
			},
			{
				img: '/images/images/ELN-A/DJI_0358-2.jpg',
				title: 'El Nido Joining Boat Tours',
			},
			{
				img: '/images/destinations/eln.jpg',
				title: 'El Nido Private Boat Tours',
			},
			{
				img: '/images/images/ELN-B/TB2.jpg',
				title: 'El Nido Island Tours and Activities',
			},
		],
	}),
	actions: {
		setIsMobile(isMobile: boolean) {
			this.mobile = isMobile;
		},

		changeCountry(currency, multipliers) {
			this.listings = listings.map((i) => {
				const ratePlan = JSON.parse(JSON.stringify(i.ratePlan));
				const { images, thumbnail } = generateImages(i);
				return {
					...i,
					ratePlan: {
						base: Math.round(parseFloat(ratePlan.base) * multipliers),
						inc: parseInt(ratePlan.inc),
						extra: Math.round(parseFloat(ratePlan.extra) * multipliers),
						currency: currency,
					},
					images,
					thumbnail,
					rateTable: calculatePricing(ratePlan),
				};
			});
			this.bestsellers = this.listings.filter((item) => ['PV-PPS-ELN', 'PV-PPUR-PPS-ELN', 'PV-ELN-PPS', 'PV-PPUR-ELN-PPS', 'PV-PPS-PBT', 'PV-PBT-ELN'].includes(item.code));
		},
	},
	getters: {
		getListingBySlug: (state) => (slug) => {
			const listing = state.listings.find((item) => item.slug === slug);
			listing.suggestions = getRandomSubset(
				state.mobile ? 3 : 6,
				state.listings.filter((item) => item.slug !== slug && item.origin === listing.destination)
			);
			return listing;
		},

		getBestSellers: (state) => state.bestsellers,
		getListings: (state) => state.listings,
		getListingsByQuery: (state) => (query) => {
			let listingItems = [];
			if (query === '/shared-minivan-transfer') listingItems = state.listings.filter((item) => item.code.includes('SV') && item.listing_type === 'SHARED_TRANSFER');
			if (query === '/private-transfers') listingItems = state.listings.filter((item) => item.code.includes('PV') && !item.code.includes('PPUR'));
			if (query === '/puerto-princesa-underground-river-tour') listingItems = state.listings.filter((item) => item.code.includes('PPUR'));
			if (query === '/tours-in-puerto-princesa') listingItems = state.listings.filter((item) => ['JOINING_TOUR', 'PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'PPS');
			if (query === '/el-nido-joining-boat-tours') listingItems = state.listings.filter((item) => ['JOINING_TOUR'].includes(item.listing_type) && item.origin === 'ELN');
			if (query === '/el-nido-private-boat-tours') listingItems = state.listings.filter((item) => ['PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'ELN');
			if (query === '/private-transfers-with-underground-river-tour') listingItems = state.listings.filter((item) => item.code.includes('PV') && item.code.includes('PPUR'));
			if (query === '/el-nido-island-tours-and-activities') listingItems = state.listings.filter((item) => ['JOINING_TOUR', 'PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'ELN');
			const data: { img: string; title: string; listings?: any[] } = state.currateList.find((i) => `/${slugify(i.title)}` === query);
			data.listings = listingItems;
			return data;
		},
		getCurratedListing: (state) => {
			let listingItems = [];
			const curratedListings = [];
			for (const currentedListItem of state.currateList) {
				const query = `/${slugify(currentedListItem.title)}`;

				if (query === '/shared-minivan-transfer') listingItems = state.listings.filter((item) => item.code.includes('SV') && item.listing_type === 'SHARED_TRANSFER');
				if (query === '/private-transfers') listingItems = state.listings.filter((item) => item.code.includes('PV') && !item.code.includes('PPUR'));
				if (query === '/puerto-princesa-underground-river-tour') listingItems = state.listings.filter((item) => item.code.includes('PPUR'));
				if (query === '/tours-in-puerto-princesa') listingItems = state.listings.filter((item) => ['JOINING_TOUR', 'PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'PPS');
				if (query === '/el-nido-joining-boat-tours') listingItems = state.listings.filter((item) => ['JOINING_TOUR'].includes(item.listing_type) && item.origin === 'ELN');
				if (query === '/el-nido-private-boat-tours') listingItems = state.listings.filter((item) => ['PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'ELN');
				if (query === '/private-transfers-with-underground-river-tour') listingItems = state.listings.filter((item) => item.code.includes('PV') && item.code.includes('PPUR'));
				const data: { img: string; title: string; listings?: any[] } = state.currateList.find((i) => `/${slugify(i.title)}` === query);
				data.listings = listingItems;
				curratedListings.push(data);
			}

			return curratedListings;
		},
	},
	persist: {
		storage: persistedState.cookiesWithOptions({
			sameSite: 'strict',
		}),
	},
});
