import { useCurrencyStore } from '~/stores/currencyStore';
import { defineStore } from 'pinia';
import { makeRef, nationalities as demographics } from '~/utils';
import { apiPost } from '~/utils/api';
export const useBookingStore = defineStore('booking', {
    state: () => ({
        bookingRef: makeRef(),
        booker: {
            name: null,
            email: null,
            mobile: null,
        },
        userCountry: 'US',
        cartItems: [],
        isBookerInfoOpened: false,
        isMobile: false,
        currentListing: null,
        isBookingDiagOpen: false,
        conversionData: {
            value: 0,
            currency: 'USD',
            transaction_id: null,
        },
        searchResults: [],
        searchData: null,
        placeSearchCache: [],
        nationalities: [...demographics],
        currencies: [],
    }),

    actions: {
        resetState() {
            this.bookingRef = makeRef();
            this.booker = {
                name: null,
                email: null,
                mobile: null,
            };
            this.userCountry = 'US';
            this.cartItems = [];
            this.isBookerInfoOpened = false;
            this.isMobile = false;
            this.currentListing = null;
            this.isBookingDiagOpen = false;
            this.conversionData = {
                value: 0,
                currency: 'USD',
                transaction_id: null,
            };
            this.searchResults = [];
            this.searchData = null;
            this.placeSearchCache = [];
            this.nationalities = [...demographics];
        },

        setUserCountry(countryCode) {
            this.userCountry = countryCode;
        },

        setIsBookingDiagOpen() {
            this.isBookingDiagOpen = !this.isBookingDiagOpen;
        },

        setBookerInfoToggle() {
            this.isBookerInfoOpened = !this.isBookerInfoOpened;
        },

        setIsMobile(value) {
            this.isMobile = value;
        },

        setCurrentListing(value) {
            this.currentListing = value;
        },

        setConversionData(value) {
            this.conversionData = value;
        },

        setSearchResults(value) {
            this.searchResults = value;
        },

        setCartData(ref, value, contactInfo) {
            this.bookingRef = ref;
            this.cartItems = value;
            this.booker = contactInfo;
            this.updateCartItemCurrency();
        },

        addCartItem(value) {
            const { currency } = useCurrencyStore();

            const cartItemIndex = this.cartItems.findIndex((item) => item.ref === value.ref);
            if (cartItemIndex === -1) {
                this.cartItems.push({ ...value, ref: makeRef() });
            } else {
                this.cartItems[cartItemIndex] = value;
            }
            apiPost(`/api/cart/upsert/${this.bookingRef}`, {
                content: { contactInfo: this.booker, bookingItems: this.cartItems, currency: currency },
            });
        },

        removeCartItem(ref) {
            const { currency } = useCurrencyStore();

            this.cartItems = this.cartItems.filter((item) => item.ref !== ref);
            apiPost(`/api/cart/upsert/${this.bookingRef}`, {
                content: { contactInfo: this.booker, bookingItems: this.cartItems, currency: currency },
            });
        },

        updateCartItemCurrency() {
            const { currency } = useCurrencyStore();

            this.cartItems = this.cartItems.map((cartItem) => {
                const { rate, total, ratePlan } = cartItem;

                const { base, extra, currency: itemCurrency = 'USD', inc } = ratePlan;

                const newItem = {
                    ...cartItem, ratePlan: {
                        inc, currency,
                        base: currencyConverter(base, itemCurrency, currency),
                        extra: currencyConverter(extra, itemCurrency, currency),
                    },
                    rate: currencyConverter(rate, itemCurrency, currency),
                    total: currencyConverter(total, itemCurrency, currency),
                };
                return newItem;

            });

            if (this.cartItems.length > 0) {
                apiPost(`/api/cart/upsert/${this.bookingRef}`, {
                    content: { contactInfo: this.booker, bookingItems: this.cartItems, currency: currency },
                });
            }


        },

        setBookerInfo(value) {
            this.booker = value;
        },

        clearCart() {
            this.cartItems = [];
            this.conversionData = {
                value: 0,
                currency: 'USD',
                transaction_id: null,
            };
        },

        setSearchData(value) {
            this.searchData = value;
        },

        setPlaceSearchCache(value) {
            const cache = getUniqueByKey([...this.placeSearchCache, ...value], 'place_id');
            this.placeSearchCache = cache;
        },
    },

    getters: {
        getBookingRef: (state) => state.bookingRef,
        getIsBookingDiagOpen: (state) => state.isBookingDiagOpen,
        getConversionData: (state) => state.conversionData,
        getBookerInfo: (state) => state.booker,
        isBookingInitiated: (state) => state.booker.name !== null,
        getCartCount: (state) => state.cartItems.length,
        getCartItems: (state) => state.cartItems,
        getBookerInfoToggle: (state) => state.isBookerInfoOpened,
        getSearchResults: (state) => state.searchResults,
        getSearchData: (state) => state.searchData,
        getPlaceSearchCache: (state) => state.placeSearchCache,
        getNationalities: (state) => state.nationalities,
        getUserCountry: (state) => state.userCountry,
    },
    persist: {
        storage: persistedState.localStorage,
    },
});
