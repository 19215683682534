const imageResources = {
    UR: [
        '/images/images/UR/647d77d021a62f001dfe8de9.jpg',
        '/images/images/UR/puerto-princesa-underground-river-by-fgb-travel-and-tours.jpg',
        '/images/images/UR/UR1.jpeg',
        '/images/images/UR/UR2.jpg',
        '/images/images/UR/UR3.jpg',
        '/images/images/UR/UR4.jpg',
        '/images/images/UR/UR5.jpg',
    ],
    VAN: [
        '/images/images/VAN/db747193eea37b3208f66dc63d32f1df.JPG',
        '/images/images/VAN/nissan-urvan-premium-601a75c16be6d.jpg',
        '/images/images/VAN/urvan.png',
        '/images/images/VAN/Van1.webp',
        '/images/images/VAN/Van2.jpg',
        '/images/images/VAN/VAN3.jpeg',
        '/images/images/VAN/Van4.webp'
    ],
    'ELN-A': [
        '/images/images/ELN-A/DJI_0358-2.jpg',
        '/images/images/ELN-A/Tour-A-74.jpg',
        '/images/images/ELN-A/TA1.jpg',
        '/images/images/ELN-A/TA2.jpg',
        '/images/images/ELN-A/TA3.webp',
        '/images/images/ELN-A/TA4.webp'
    ],
    'ELN-B': [
        '/images/images/ELN-B/el-nido-island-hopping-4.jpg',
        '/images/images/ELN-B/el-nido-island-hopping-5.jpg',
        '/images/images/ELN-B/snake-island-tour-b-el-nido-sand-bar.jpeg',
        '/images/images/ELN-B/TB1.jpeg',
        '/images/images/ELN-B/TB3.jpeg',
        '/images/images/ELN-B/TB5.jpeg',
        '/images/images/ELN-B/TB2.jpg',
        '/images/images/ELN-B/TB4.jpg',

    ],
    'ELN-C': [
        '/images/images/ELN-C/hidden-beach.jpg',
        '/images/images/ELN-C/secret-beach-on-el-nido-tour-c.jpg',
        '/images/images/ELN-C/TC4.jpg',
        '/images/images/ELN-C/TC1.jpeg',
        '/images/images/ELN-C/TC2.jpeg',
        '/images/images/ELN-C/TC3.jpeg',
    ],
    'ELN-D': ['/images/images/ELN-D/DJI_0573-3.jpg',
        '/images/images/ELN-D/TD1.jpeg',
        '/images/images/ELN-D/TD2.jpg',
        '/images/images/ELN-D/TD3.jpg',
        '/images/images/ELN-D/TD4.jpeg',
        '/images/images/ELN-D/TD5.webp'
    ],
    HB: [
        '/images/images/HB/1027-0302_honda-bay-island-hopping-tour-from-puerto-princesa-phillipines-pelago8.jpg',
        '/images/images/HB/Puerto-Princesa-Tour-8979-1.jpg',
        '/images/images/HB/honda-bay-island-hopping.jpg',
        '/images/images/HB/HB1.jpeg',
        '/images/images/HB/HB4.jpeg',
        '/images/images/HB/HB3.jpeg',
        '/images/images/HB/HB2.jpg',
    ],
    CT: [
        '/images/images/CT/33024988422_f5acd1ce73_c.jpg',
        '/images/images/CT/58efab24a64d3eff9c346afcfb4dd23c.jpg',
        '/images/images/CT/Puerto-Princesa-church.jpg',
        '/images/images/CT/plaza-cuartel-puerto-princesa.jpg'
    ]
};
export { imageResources };