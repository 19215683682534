<template>
	<NuxtLayout>
		<NuxtPwaManifest />
		<NuxtPage class="mt-15" />
	</NuxtLayout>
</template>
<script setup>
import { useBookingStore } from '~/stores/bookingStore';
import { useListingStore } from './stores/listingStores';
import { useCurrencyStore } from './stores/currencyStore';
import { storeToRefs } from 'pinia';
const { setIsMobile } = useListingStore();
const currencyStore = useCurrencyStore();
const { currency, country, locale } = storeToRefs(currencyStore);
const { initCurrency, updateRates } = currencyStore;
const store = useBookingStore();
const cartSize = toRef(() => store.getCartCount);
const userCountry = toRef(() => store.getUserCountry);
import { useDisplay } from 'vuetify';
const { mobile } = useDisplay();
watch(cartSize, async () => {
	if (cartSize.value > 0 && process.client && window) {
		await nextTick();
		window.scrollTo(0, 0);
		setTimeout(() => shaker('cart'), 500);
	}
});

callOnce(() => {
	setIsMobile(mobile.value);

	const userCountryHeader = useRequestHeader('x-vercel-ip-country') || 'US';
	const userLocaleHeader = useRequestHeader('accept-language')?.split(',')[0] || 'en-US';
	initCurrency(userCountryHeader, userLocaleHeader);
});
onMounted(() => {
	if (userCountry.value !== country.value) {
		initCurrency(userCountry.value, locale.value);
	}
});
watch(currency, () => {
	store.updateCartItemCurrency();
});
</script>
